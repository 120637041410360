import {getScrollTop} from 'get-scroll';
import PageComponent from '../../common/component/page-component';


class MainMenu extends PageComponent {


	constructor({root, element, scrollDownClass = 'scrolledDown', menuOverflowClass = 'menuOverflow', measureWidthClass = 'measureWidth', subMenuClass = 'subMenu', subMenuAndMainMenuClass = 'subMenuAndMainMenu'}) {
		super({root: root, element: element});
		this.scrollDownClass = scrollDownClass;
		this.menuOverflowClass = menuOverflowClass;
		this.measureWidthClass = measureWidthClass;
		this.subMenuClass = subMenuClass;
		this.subMenuAndMainMenuClass = subMenuAndMainMenuClass;
	}


	prepare() {
		this.listeners.menuToggle = this.events.on(this.element, this.dataSelector('menuToggle'), 'click', this.onMenuToggle.bind(this));
		this.listeners.menuClick = this.events.on(this.element, this.dataSelector('menuLink'), 'click', this.onMenuClick.bind(this), {capture: true});
		this.listeners.showMainMenuClick = this.events.on(this.element, this.dataSelector('subMenuClose'), 'click', this.onShowMainMenuClick.bind(this), {capture: true});
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.subMenuSlot = document.querySelector(this.dataSelector('sectionSlot', 'subMenu'));
		this.listeners.sectionChange = this.events.on(this.subMenuSlot, 'section:change', this.onsubMenuChange.bind(this));
		this.body = document.querySelector(this.dataSelector('body'));
		this.lastScrollTop = getScrollTop();
	}


	start() {
		this.resizing();
		this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
	}


	stop() {
		this.listeners.scroll.destroy();
		delete this.listeners.scroll;
	}


	onScroll(event) {
		const scrollTop = getScrollTop();
		if (this.lastScrollTop !== scrollTop) {
			const isScrollingDown = this.lastScrollTop < scrollTop;
			this.classList(this.element).toggle(this.scrollDownClass, (isScrollingDown && scrollTop >= this.menuMinHeight));
			if (isScrollingDown) {
				this.classList(this.element).remove(this.subMenuAndMainMenuClass);
			}
			this.lastScrollTop = scrollTop;
		}
	}


	onMenuToggle(event) {
		console.log('contexts', this.contexts);
		if (this.contexts.getCurrentContext().getName() === 'menu') {
			this.contexts.pop();
		} else {
			this.contexts.push('menu');
		}
	}


	onMenuClick(event, target) {
		if (this.contexts.getCurrentContext().getName() === 'menu') {
			this.contexts.pop();
		}
		target.blur();
	}


	onShowMainMenuClick(event) {
		this.classList(this.element).add(this.subMenuAndMainMenuClass);
	}


	onResize(event) {
		this.resizing();
	}


	resizing() {
		this.menuMinHeight = parseInt(window.getComputedStyle(this.element, null).getPropertyValue('min-height'), 10);
		this.menuLinkLists = this.element.querySelectorAll(this.dataSelector('menuWrapper'));
		this.classList(this.element).remove(this.menuOverflowClass);
		let overflowing = false;
		for (const list of this.menuLinkLists) {
			if (list.clientWidth > 0 && list.clientWidth < list.scrollWidth) {
				overflowing = true;
				break;
			}
		}
		this.classList(this.element).toggle(this.menuOverflowClass, overflowing);
		// this.classList(this.element).add(this.measureWidthClass);
		// this.classList(this.element).remove(this.menuOverflowClass);
		// const menuIsOverflowing = (this.menuLinkList.clientWidth < this.menuLinkList.scrollWidth);
		// this.classList(this.element).remove(this.measureWidthClass);
		// this.classList(this.element).toggle(this.menuOverflowClass, menuIsOverflowing);
	}


	onsubMenuChange(event, target) {
		const subMenuSlotFilled = !!this.subMenuSlot.querySelector('*');
		this.classList(this.body).toggle(this.subMenuClass, subMenuSlotFilled);
		//NOTE: maybe remove snd use of subMenuClass on header
		this.classList(this.element).toggle(this.subMenuClass, subMenuSlotFilled);
	}
}

export default MainMenu;
