import PageComponent from '../component/page-component';
import apiMixin from '../api/api-mixin';


class AcceptCookie extends apiMixin(PageComponent) {

	constructor({element, root, apiCommand = 'cookie/accept', hideClass = 'hidden'}) {
		super({element: element, root: root});
		this.apiCommand = apiCommand;
		this.hideClass = hideClass;
		this.busy = false;
	}



	prepare() {
		this.listeners.click = this.events.on(this.element.querySelector(this.dataSelector('acceptCookieAction')), 'click', this.onActionClick.bind(this));
	}


	onActionClick(event) {
		if (!this.busy) {
			this.api.execute(this.apiCommand, {}).then((response) => {
				this.classList(this.element).add(this.hideClass);
				this.onTransitionEnd(this.element).then(() => {
					this.element.parentNode.removeChild(this.element);
				});
			});
		}
	}

}

export default AcceptCookie;
