import InteractiveField from './interactive-field';


class MultirangeField extends InteractiveField {

	prepare() {
		super.prepare();
		this.valueDisplay = this.element.querySelector(this.dataSelector('valueDisplay'));
		this.listeners.rangeChange = this.events.on(this.element, 'input[type="range"]', 'input', this.onRangeInput.bind(this));
		const input = this.getInput();
		this.min = input[0].min;
		this.max = input[0].max;
		this.value = [input[0].value, input[1].value];
		this.updateRange();
	}


	onRangeInput(event, target) {
		this.updateRange();
	}


	updateRange() {
		const input = this.getInput();
		const value = [input[0].value, input[1].value];
		const minValue = Math.min(...value);
		const maxValue = Math.max(...value);
		const min = (minValue - this.min) / (this.max - this.min) * 100;
		const max = 100 - ((maxValue - this.min) / (this.max - this.min) * 100);
		this.element.style.setProperty('--rangeMin', min + '%');
		this.element.style.setProperty('--rangeMax', max + '%');
		if (this.valueDisplay) {
			this.valueDisplay.textContent = minValue + ' - ' + maxValue;
		}
	}



	getInput() {
		if (!this.input) {
			this.input = [
				this.element.querySelector('input[type="range"]' + this.dataSelector('first')),
				this.element.querySelector('input[type="range"]' + this.dataSelector('second'))
			];
		}
		return this.input;
	}


	writeValue(value) {
		const minValue = Math.min(...value);
		const maxValue = Math.max(...value);
		const input = this.getInput();
		input[0].value = minValue;
		input[1].value = maxValue;
		this.updateRange();
	}


	readValue() {
		const input = this.getInput();
		const value = [input[0].value, input[1].value];
		const minValue = Math.min(...value);
		const maxValue = Math.max(...value);

		return [minValue, maxValue];
	}


	setFocus() {
		const input = this.getInput();
		input[0].focus();
	}


	setBlur() {
		const input = this.getInput();
		input[0].blur();
		input[1].blur();
	}


	enableInput() {
		const input = this.getInput();
		input[0].disabled = false;
		input[1].disabled = false;
	}


	disableInput() {
		const input = this.getInput();
		input[0].disabled = true;
		input[1].disabled = true;
	}


}


export default MultirangeField;
