import domMixin from '../dom/dom-mixin';


class StyleChecker extends domMixin() {

    constructor({attributeName = 'style'} = {}) {
		super();
		this.attributeName = attributeName;
    }

    checkStyle(name) {
		return new Promise((resolve) => {
			const check = () => {
				const style = document.querySelector(this.dataSelector(this.attributeName, name));
				if (!style) {
					setTimeout(check, 0);
				} else {
					try {
						if (style.sheet.cssRules) {	// <--- only populated when file is loaded and applied
							requestAnimationFrame(() => {
								resolve();
							});
						} else {
							setTimeout(check, 0);
						}
					} catch (e) {
						// just loop again
						setTimeout(check, 0);
					}
				}
			};
			check();
		});
    }
}


export default StyleChecker;
