import Search from './search';
import SearchEngine from './search-engine';
import ApiSearchEngine from './api-search-engine';
import SearchResults from './search-results';
import ServerSideSearchResults from './server-side-search-results';
// import SearchPageTransition from './search-page-transition';
// import TagsVisibilityToggler from './tags-visibility-toggler';

const config = (di) => {
	di
		.setType({type: Search, name: 'Search', parent: 'PageComponent', setters: {
				injectSearchEngine: di.lazyGet('search/engine'),
				injectHistory: di.lazyGet('navigation/history')
			}
		})

		// .setType({type: TagsVisibilityToggler, name: 'TagsVisibilityToggler', parent: 'PageComponent'})

		// .setType({type: SearchPageTransition, name: 'SearchPageTransition', parent: 'PageTransition'})

		.setType({type: SearchEngine, name: 'SearchEngine'})

		.setType({type: ApiSearchEngine, name: 'ApiSearchEngine', parent: 'SearchEngine', params: {
				api: di.lazyGet('api/api')
			}
		})
		.set('search/engine', di.lazyNew('ApiSearchEngine'))

		.setType({type: SearchResults, name: 'SearchResults', parent: 'PageComponent'})
		.setType({type: ServerSideSearchResults, name: 'ServerSideSearchResults', parent: 'SearchResults'})

	;
};


export default config;
