export default function (content, name, params, classes, pageLevelContent = '') {
	return `
	<div ${this.html.classAttr(classes)} ${this.html.dataAttr({page: name, params: params})}>
		${pageLevelContent}
		<div ${this.html.classAttr('blocks')}>
			<section ${this.html.classAttr('blocks__block', 'block')}>
				<div ${this.html.classAttr('block__content')}>
					${content}
				</div>
			</section>
		</div>
	</div>
	`;
}
