import ApiPage from '../api-page';


class ApplicationsPage extends ApiPage {

	constructor({root, element, url = '', title = '', currentClass = 'current'}) {
		super({root: root, element: element, url: url, title: title});
		this.currentClass = currentClass;
	}


	onLoad() {
		const expireLinks = this.element.querySelectorAll(this.dataSelector('applicationClosing'));
		this.expireLinks = new Map();
		for (const expireLink of expireLinks) {
			const closing = this.dataAttr(expireLink).get('applicationClosing');
			if (closing > 0) {
				this.expireLinks.set(expireLink, closing);
			}
		}

		if (this.expireLinks.size) {
			this.beginTime = Date.now() / 1000;
			this.expireInterval = setInterval(this.onExpireInterval.bind(this), 500);
		} else {
			this.expireInterval = null;
		}
		return super.onLoad().then(() => {
			const form = this.getComponent('Form');
			if (form) {
				this.form = form;
				this.submitListener = this.events.on(this.element, 'form:submit', this.onFormSubmit.bind(this));
				if (this.form !== null) {
					this.appformField = this.form.getField('appformId');
					this.currentId = String(this.appformField.getValue());
					this.descriptions = new Map();
					this.descriptionsElement = null;
					const elements = this.element.querySelectorAll(this.dataSelector('appformDescription'));
					for (const element of elements) {
						const id = String(this.dataAttr(element).get('appformDescription'));
						this.descriptions.set(id, element);
						if (!this.descriptionsElement) {
							this.descriptionsElement = element.parentNode;
						}
					}
					this.classList(this.descriptions.get(this.currentId)).add(this.currentClass);
					this.appformListener = this.events.on(this.appformField.getElement(), 'field:change', this.onAppformChange.bind(this));
				}
			} else {
				this.form = null;
			}


			this.listeners.deleteApp = this.events.on(this.element, this.dataSelector('action', 'deleteApplication'), 'click', this.onDeleteApplication.bind(this));


			const introLinks = this.element.querySelectorAll(this.dataSelector('introText') + ' a');
			for (const link of introLinks) {
				link.setAttribute('target', '_blank');
				link.setAttribute('rel', 'noopener');
			}
		});
	}


	onUnload() {
		if (this.expireInterval !== null) {
			clearInterval(this.expireInterval);
		}
		if (this.submitListener) {
			this.submitListener.destroy();
		}
		if (this.appformListener) {
			this.appformListener.destroy();
			this.descriptions.clear();
		}
	}


	onExpireInterval() {
		const diff = (Date.now() / 1000) - this.beginTime;
		for (const [expireLink, closing] of this.expireLinks) {
			if (diff >= closing) {
				expireLink.parentNode.removeChild(expireLink);
				this.expireLinks.delete(expireLink);
			}
		}
		if (this.expireLinks.size === 0) {
			clearInterval(this.expireInterval);
			this.expireInterval = null;
		}
	}


	onAppformChange(event) {
		this.appformChanged(this.appformField.getValue());
	}


	onDeleteApplication(event, target) {
		target.blur();
		const id = this.dataAttr(target).get('id');
		this.getDialog().open({
			title: '',
			text: this.locale.get('application/confirmRemoveText', {id: id}),
			buttons: [
				{
					label: this.locale.get('application/cancel'),
					modifier: 'secondary',
					value: false,
					preIcon: 'close'
				},
				{
					label: this.locale.get('application/delete'),
					value: true,
					postIcon: 'check'
				}
			],
			options: {
				closeOnKey: 'esc'
			}
		}).then((confirmed) => {
			if (confirmed) {
				return this.api.execute('application/remove', {element: {id: id}});
			}
			return false;
		}).then((response) => {
			if (response !== false) {
				if (response.isSuccess()) {
					this.notify({type: 'success', text: this.locale.get('application/removed')});
					const row = this.element.querySelector(this.dataSelector('applicationItem', id));
					if (row) {
						row.parentNode.removeChild(row);
					}
					// return this.refresh();
				} else {
					console.log('error', response);
				}
			}
			return false;
		}).catch((error) => {
			console.log('remove error', error);
		});
	}


	appformChanged(newId) {
		return new Promise((resolve) => {
			const current = this.descriptions.get(this.currentId);
			const next = this.descriptions.get(newId);
			const height = Math.max(current.getBoundingClientRect().height, next.getBoundingClientRect().height);
			this.descriptionsElement.style.height = height + 'px';
			this.classList(current).remove(this.currentClass);
			this.classList(next).add(this.currentClass);
			this.onTransitionEnd(next).then(() => {
				this.descriptionsElement.style.removeProperty('height');
				this.currentId = newId;
				resolve();
			});
		});
	}


	onFormSubmit(event) {
		this.submit('edit');
	}


	submit(redirectType) {
		const form = this.form;
		const values = form.getValue();
		form.disableSubmit();
		return this.api.execute('application/add', {element: values}).then((response) => {
			if (response.isSuccess()) {
				const redirectUrl = this.locale.format(this.params.editUrl, response.output.element);
				this.navigation.redirect(redirectUrl);
			} else {
				if (response.isNotValid()) {
					form.setValueAndErrors(response.output.element);
				}
			}
			return response;
		}).catch((error) => {
			console.log('submit error', error);
		}).then(() => {
			form.enableSubmit();
		});
	}



}


export default ApplicationsPage;
