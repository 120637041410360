import namedRegexp from 'named-js-regexp';
import PageComponent from '../component/page-component';
import templateMixin from '../template/template-mixin';

// exception required for XregExp:
/*eslint-disable new-cap*/


class VideoDiscovery extends templateMixin(PageComponent) {

	constructor({
		element,
		root,
		types = {
			youtube: {
				defaultVars: {
					host: 'youtube.com'
				},
				patterns: [
					'((?<host>youtube.com)/embed/(?<id>[a-z0-9_-]+))',
					'((?<host>youtube-nocookie.com)/embed/(?<id>[a-z0-9_-]+))',
					'(v=(?<id>[a-z0-9_-]+))',
					'(youtu.be/(?<id>[a-z0-9_-]+))'
				],
				attributes: {
					src: 'https://www.youtube-nocookie.com/embed/{{id}}{{params}}',
					frameborder: '0',
					webkitAllowFullScreen: 'true',
					mozAllowFullScreen: 'true',
					allowFullScreen: 'true',
					width: '100%',
					height: '100%'
				}
			},

			vimeo: {
				defaultVars: {},
				patterns: [
					'(vimeo.com/(?<id>[0-9]+))'
				],
				attributes: {
					src: '//player.vimeo.com/video/{{id}}{{params}}',
					frameborder: '0',
					webkitAllowFullScreen: 'true',
					mozAllowFullScreen: 'true',
					allowFullScreen: 'true',
					width: '100%',
					height: '100%'
				}
			}
		},
		tpl = 'media/video',
		wrapperClasses = ['videoPlayer'],
		wrapperData = {},
		playerClasses = ['videoPlayer__player'],
		playerData = {}
	} = {}) {
		super({element: element, root: root});
		this.types = types;
		this.wrapperClasses = wrapperClasses;
		this.wrapperData = wrapperData;
		this.playerClasses = playerClasses;
		this.playerData = playerData;
		this.tpl = tpl;
	}


	prepare() {
		const content = this.element.innerHTML;
		const urls = this.discoverVideoUrls(content, this.types);
		const players = this.getPlayersByUrls(urls);
		if (players.length) {
			this.element.insertAdjacentHTML('afterend', players.join('\n'));
		}
	}


	discoverVideoUrls(content, types) {
		const urls = [];
		for (const type in types) {
			if (types.hasOwnProperty(type)) {
				for (const pattern of types[type].patterns) {
					// pattern = XRegExp(pattern, 'gix');
					const matches = namedRegexp(pattern, 'gi').exec(content);
					const matched = matches !== null;
					if (matched) {
						urls.push({
							type: type,
							params: matches.groups()
						});
					}
				}
			}
		}
		return urls;
	}


	getPlayersByUrls(urls) {
		const players = [];
		for (const entry of urls) {
			const type = this.types[entry.type];
			const params = Object.assign({}, type.defaultVars, entry.params);
			const attributes = {};
			for (const attrName in type.attributes) {
				if (type.attributes.hasOwnProperty(attrName)) {
					const attribute = type.attributes[attrName];
					attributes[attrName] = attribute.replace(/{{([^}]+)}}/g, (match, name) => {
						if (name in params) {
							return params[name];
						}
						return '';
					});
				}
			}
			players.push(this.template.render(this.tpl, attributes, this.playerClasses, this.playerData, this.wrapperClasses, this.wrapperData));
		}
		return players;
	}

}


export default VideoDiscovery;
