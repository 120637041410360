import ClientRenderedPageLoader from '../../../common/page/client-rendered-page-loader';


class PreviewApplicationPageLoader extends ClientRenderedPageLoader {

	requestPage(request) {
		const requestParams = this.prepareRequestParams(request);
		return this.api.execute('application/view', {element: {applicationId: requestParams.id}}).then((response) => {
			if (response.isSuccess()) {
				const content = this.template.render(
					'page/application/preview',
					'previewApplication',
					requestParams,
					['page', 'page--applicationPreview'],
					response.output.application,
					requestParams.backUrl,
					response.output.introText,
					response.output.outtroText
				);
				return {content: content, title: requestParams.title};
			}
			return false;
		}).catch((error) => {
			console.log('error', error);
			return false;
		});
	}


	prepareRequestParams(request) {
		const requestParams = {};
		for (const name in request.params) {
			if (request.params.hasOwnProperty(name) && request.params[name] !== undefined) {
				requestParams[name] = request.params[name];
			}
		}
		return requestParams;
	}

}


export default PreviewApplicationPageLoader;
