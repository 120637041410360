export default function (name, params, classes, form) {
	this.wrap('page/wrapper', name, params, classes);
	return `
	<div ${this.html.classAttr('block__pageTitle')}>
		<h2>${this.text.get('account/profileTitle')}</h2>
	</div>

	<div ${this.html.classAttr('page__form')}>
		${this.render(
			'form/form',
			form,
			'user/profile',
			['form--profile'],
			{}
		)}
	</div>
	`;
}
