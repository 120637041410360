class SearchEngine {

	search(params) {
		return Promise.resolve({});
	}


	track(params) {
		return Promise.resolve({});
	}

}


export default SearchEngine;
