import SearchResults from './search-results';


class ServerSideSearchResults extends SearchResults {

	processResults(results) {
		return new Promise((resolve) => {
			this.element.innerHTML = results.content;
			resolve(results);
		});
	}


	reset() {
		this.element.innerHTML = '';
	}


}


export default ServerSideSearchResults;
