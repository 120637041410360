const templateMixin = (Base = class Empty {}) => class extends Base {

	injectTemplate(template) {
		this.template = template;
	}

};


export default templateMixin;
