import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {trimUrl} from '../../common/utils/string';
import PageTransition from '../../common/page/page-transition';

gsap.registerPlugin(ScrollToPlugin);

class DefaultPageTransition extends PageTransition {

	constructor({pageLoader, enteringClass = 'entering', leavingClass = 'leaving', scrollDuration = 0.5, scrollDelay = 0}) {
		super({pageLoader: pageLoader});
		this.enteringClass = enteringClass;
		this.leavingClass = leavingClass;
		this.scrollDuration = scrollDuration;
		this.scrollDelay = scrollDelay;
	}


	execute(request) {
		const currentContext = this.contexts.getCurrentContext();
		const currentDefaultPage = this.slots.get('default').getCurrentPage();
		let promise = Promise.resolve();
		//console.log('uris', currentDefaultPage ? currentDefaultPage.getUrl() : null, request.url, trimUrl(request.url.split('?')[0]));
		if (currentContext.getName() === 'overlay') {
			promise = promise.then(() => this.contexts.pop());
			if (request.navigationType === 'pop' && currentDefaultPage && currentDefaultPage.getUrl() === trimUrl(request.url.split('?')[0])) {
				return promise.then(() => {
					this.commit(request, {});
					return Promise.resolve({});
				});
			}
		}
		return promise.then(() => super.execute(request));
	}


	attachNewPage(newPage, oldPage, request, response) {
		super.attachNewPage(newPage, oldPage, request, response);
		if (oldPage) {
			this.classList(oldPage.getElement()).add(this.leavingClass);
		}
	}


	swapPages(newPage, oldPage, request, response) {
		if (newPage && oldPage) {
			const newElement = newPage.getElement();
			const oldElement = oldPage.getElement();
			const parent = oldElement.parentNode;
			const height = Math.max(newElement.getBoundingClientRect().height, oldElement.getBoundingClientRect().height);
			parent.style.height = height + 'px';
			this.classList(newElement).add(this.enteringClass);
			const scrollPos = (request.state && ('scrollTop' in request.state)) ? request.state.scrollTop : 0;
			gsap.to(window, {duration: this.scrollDuration, delay: this.scrollDelay, scrollTo: {y: scrollPos, autoKill: false}});
			return this.onTransitionEnd(newElement).then(() => {
				parent.style.removeProperty('height');
				this.classList(newElement).remove(this.enteringClass);
			});
		}
		return Promise.resolve();
	}

}


export default DefaultPageTransition;
