import {getScrollTop} from 'get-scroll';

import getOffsetTop from '../../common/utils/offset-top';
import {ucFirst} from '../../common/utils/string';
import Context from '../../common/context/context';


class DefaultContext extends Context {

	constructor({name, root, popOnKey = false, outClass = 'covered'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.prevScrollPos = 0;
		this.outClass = outClass;
		this.outClassModifier = '';
	}


	switchIn(otherContext) {
		const element = this.getElement();
		this.classList(element).remove(this.outClass, this.outClassModifier);
		element.style.removeProperty('top');
		window.scrollTo(0, this.prevScrollPos);
		return otherContext;
	}


	switchOut(otherContext) {
		const element = this.getElement();
		const currentOffset = getOffsetTop(element);
		const scrollPos = getScrollTop();
		this.outClassModifier = this.outClass + 'By' + ucFirst(otherContext.getName());
		this.prevScrollPos = scrollPos;
		this.classList(element).add(this.outClass, this.outClassModifier);
		element.style.top = (currentOffset - scrollPos) + 'px';
		window.scrollTo(0, 0);
		return otherContext;
	}

}


export default DefaultContext;
