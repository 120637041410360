export default function (entry, readonly = false) {
	return `
		<div ${this.html.classAttr('sumTable')} ${this.html.dataAttr({component: 'SumTable', defaultValue: entry.default ? entry.default : entry.value, numberFormat: entry.numberFormat, readonly: readonly, firstLinePlaceholder: ('firstLinePlaceholder' in entry.additionalLabels ? entry.additionalLabels.firstLinePlaceholder : '')})}>
			<template ${this.html.dataAttr({rowTemplate: null})}>
				<table><!-- IE needs a proper table, otherwise will discard the row -->
				<tr ${this.html.classAttr('sumTable__row')} ${this.html.dataAttr({row: null})}>
					<td ${this.html.classAttr(['sumTable__col', 'sumTable__col--desc'])}>
						<textarea ${readonly ? 'readonly="readonly"' : ''} ${this.html.classAttr(['sumTable__input', 'sumTable__input--desc'])} ${this.html.dataAttr({desc: null})} rows="1"></textarea>
					</td>
					<td ${this.html.classAttr(['sumTable__col', 'sumTable__col--value'])}>
						<input type="text" ${readonly ? 'readonly="readonly"' : ''} ${this.html.classAttr(['sumTable__input', 'sumTable__input--value'])} ${this.html.dataAttr({value: null})} value="" />
					</td>
				</tr>
				</table>
			</template>
			<table ${this.html.classAttr('sumTable__table')}>
				<thead>
					<tr ${this.html.classAttr('sumTable__header')}>
						<th ${this.html.classAttr(['sumTable__col', 'sumTable__col--desc'])}>
							${this.escape(entry.additionalLabels.desc)}
						</th>
						<th ${this.html.classAttr(['sumTable__col', 'sumTable__col--value'])}>
							${this.escape(entry.additionalLabels.value)}
						</th>
					</tr>
				</thead>
				<tbody ${this.html.classAttr('sumTable__rows')} ${this.html.dataAttr({rows: null})}>
				</tbody>
				<tfoot>
					<tr ${this.html.classAttr('sumTable__summary')}>
						<td ${this.html.classAttr(['sumTable__col', 'sumTable__col--desc'])}>
							${this.escape(entry.additionalLabels.sum)}
						</td>
						<td ${this.html.classAttr(['sumTable__col', 'sumTable__col--value'])}>
							<input type="text" readonly="readonly" ${this.html.classAttr(['sumTable__input', 'sumTable__input--value', 'sumTable__input--total'])} ${this.html.dataAttr({sum: null})} value="" />
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	`;
}
