export default function (entry) {
	const requireCheckIcon = ['text', 'textarea', 'radio', 'tags', 'select', 'sum', 'date'].indexOf(entry.type) >= 0;
	if ('label' in entry) {
		return `
			${this.html.open({
				tag: entry.type === 'radio' ? 'legend' : 'label',
				classes: ['field__label', 'field__label--' + (entry.required ? 'required' : 'optional')],
				attributes: {
					for: entry.id,
				}
			})}
				${this.html.element({tag: 'div', classes: ['field__labelValue', 'ugc'], content: entry.label, data: ('labelData' in entry ? entry.labelData : {})})}
				${'description' in entry && entry.description.length ? entry.description : ''}
				${requireCheckIcon ? this.html.element({tag: 'span', classes: ['field__checkIcon'], data: {checkIcon: null}, content: this.html.svgIcon('field-check')}) : ''}
			${this.html.close()}
		`;
	}
	return '';
}
