import ClientRenderedPageLoader from '../../../common/page/client-rendered-page-loader';
import localeMixin from '../../../common/locale/locale-mixin';
import navigationMixin from '../../../common/navigation/navigation-mixin';
import notificationsMixin from '../../../common/notifications/notifications-mixin';


class ContractApplicationPageLoader extends notificationsMixin(localeMixin(navigationMixin(ClientRenderedPageLoader))) {

	requestPage(request) {
		const requestParams = this.prepareRequestParams(request);
		return this.api.execute('application/contract', {element: {applicationId: requestParams.id}}).then((response) => {
			let message = '';
			if (response.isSuccess()) {
				const content = this.template.render(
					'page/application/contract',
					'contractApplication',
					requestParams,
					['page', 'page--applicationContract'],
					requestParams.backUrl,
					response.output.contractText,
					response.output.contractIntroText,
					response.output.contractOuttroText
				);
				return {content: content, title: requestParams.title};
			} else if (response.isNotValid()) {
				message = this.getText('application/contractMissingDataError');
			} else {
				message = this.getText('form/error/forbidden');
			}
			this.notify({type: 'error', text: message, duration: 6});
			this.navigation.redirect(requestParams.backUrl);
			return false;
		}).catch((error) => {
			console.log('error', error);
			return false;
		});
	}


	prepareRequestParams(request) {
		const requestParams = {};
		for (const name in request.params) {
			if (request.params.hasOwnProperty(name) && request.params[name] !== undefined) {
				requestParams[name] = request.params[name];
			}
		}
		return requestParams;
	}

}


export default ContractApplicationPageLoader;
