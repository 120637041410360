import delegate from 'delegate';
import {isString, isArray, isObject, isFunction} from '../utils/types';


class Events {
	constructor({rafEvents = ['scroll', 'resize']} = {}) {
		// this.rafEvents = new Map();
		// for (const name of rafEvents) {
		// 	this.rafEvents.set('window:' + name, {listeners: new Set(), updated: false, looping: false, listener: null});
		// }
		// this.looping = false;

		this.rafEvents = {};
		for (const name of rafEvents) {
			this.rafEvents['window:' + name] = {
				listeners: new Set(),
				updated: false,
				listening: false,
				event: null,
				raf: null
			};
		}
	}


	onLoop() {
		let continueLoop = false;
		for (const [name, entry] of this.rafEvents)  {
			if (entry.looping) {
				if (entry.listeners.size) {
					continueLoop = true;
					if (entry.updated) {
						entry.updated = false;
						//console.log('triggering', name);
						this.trigger(window, name);
					}
				} else {
					entry.listener.destroy();
					entry.looping = false;
				}
			}
		}
		if (continueLoop) {
			requestAnimationFrame(this.onLoop.bind(this));
		} else {
			this.looping = false;
		}
	}


	startLoop(type) {
		const entry = this.rafEvents.get(type);
		if (!entry.looping) {
			const realEvent = type.split(':')[1];
			entry.looping = true;
			entry.listener = this.on(window, realEvent, () => { entry.updated = true; }, {capture: true, passive: true});
		}
		if (!this.looping) {
			this.looping = true;
			requestAnimationFrame(this.onLoop.bind(this));
		}
		return this;
	}


	onRafEvent(event) {
		const baseType = event.type.toLowerCase();
		const type = 'window:' + baseType;
		const entry = this.rafEvents[type];
		const callback = () => {
			entry.raf = null;
			if (entry.listeners.size) {
				const customEvent = new CustomEvent(type, {bubbles: true, cancelable: true, detail: {}});
				for (const call of entry.listeners.values()) {
					call(customEvent);
				}
			}
		};
		if (!entry.raf) {
			entry.raf = requestAnimationFrame(callback);
		}
	}

    on(element, selector, type, action, params) {
        if (isString(element)) {
            params = action;
            action = type;
            type = selector;
            selector = element;
            element = null;
        } else if(isFunction(type)) {
            params = action;
            action = type;
            type = selector;
            selector = null;
        }
        params = Object.assign({
            capture: false,
			passive: false,
			once: false,
            delegated: !!selector
        }, (isObject(params) ? params : {}));


		const options = {
			capture: params.capture,
			passive: params.passive,
			once: params.once
		};


		const listener = {};
		const types = type.split(' ');

        const callback = (event) => (this.onEvent(event, action, params));

		const delegations = [];
		let target;
		if (element === null || selector) {
			target = element !== null ? element : document;
		}

		const onRafEvent = this.onRafEvent.bind(this);

		for (const t of types) {
			if (t in this.rafEvents) {
				const entry = this.rafEvents[t];
				entry.listeners.add(callback);
				if (!entry.listening) {
					const baseType = t.split(':')[1];
					entry.listening = true;
					window.addEventListener(baseType, onRafEvent, options);
					entry.updated = false;
				}
			} else {
				if (element !== null && !selector) {
	                element.addEventListener(t, callback, options);
	            } else {
	                delegations.push(delegate(target, selector, t, callback, options));
				}
			}
		}

		listener.destroy = listener.off = listener.remove = listener.unlisten = () => {
			for (const t of types) {
				if (t in this.rafEvents) {
					const entry = this.rafEvents[t];
					entry.listeners.delete(callback);
					if (entry.listeners.size === 0 && entry.listening) {
						const baseType = t.split(':')[1];
						window.removeEventListener(baseType, onRafEvent, options);
						entry.listening = false;
						if (entry.raf) {
							cancelAnimationFrame(entry.raf);
							entry.raf = null;
						}
					}
				} else {
					if (element !== null && !selector) {
						element.removeEventListener(t, callback, options);
		            } else {
						for (const d of delegations) {
							d.destroy();
						}
					}
				}
			}
		};
        return listener;
    }


    trigger(targets, events, customData = {}) {
		if (!isArray(events)) {
			if (isString(events)) {
				events = events.split(' ');
			} else {
				events = [events];
			}
		}
		if (!isArray(targets)) {
            targets = [targets];
        }

		const realEvents = [];
		for (let event of events) {
			if (isString(event)) {
	            event = new CustomEvent(event, {bubbles: true, cancelable: true, detail: customData});
				realEvents.push(event);
	        }
			for (let target of targets) {
				if (isString(target)) {
					target = document.querySelector(target);
				}
				if (target) {
					target.dispatchEvent(event);
				} else {
					console.log('target not found to dispatch event', event);
				}
	        }
		}
		return (realEvents.length > 1 ? realEvents : realEvents[0]);
    }


    onEvent(event, action, params = {}) {
        const type = event.type.toLowerCase().replace(':', '-');

        // needed to avoid to catch right button clicks on FF
        if (['click', 'mousedown', 'mouseup'].indexOf(type) >= 0 && event.button !== 0) {
            return true;
        }

        const target = params.delegated? event.delegateTarget : event.target;
        const data = Object.assign({}, event.detail || {});
		action(event, target, data);
        return true;
    }

}

export default Events;
