import App from '../../common/app/app';


class FrontendApp extends App {

	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}


	injectErrorListener(errorListener) {
		this.errorListener = errorListener;
	}


	injectNavigationTracker(tracker) {
		this.tracker = tracker;
		this.tracker.init();
	}

	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	injectPages(pages) {
		this.pages = pages;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	injectLocaleDynamicTranslator(dynamicTranslator) {
		this.dynamicTranslator = dynamicTranslator;
	}


	init() {
		this.contexts.setDefaultContext('default', false);
		return super.init().then(() => {
			this.accountManager = this.getComponent('AccountManager');
			return this.accountManager.on('ready').then(() => {
				this.loader = this.getComponent('AppLoader');
			});
		});
	}


	execute() {
		const start = () => {
			if (this.pageSlots.get('overlay').getCurrentPage()) {
				this.contexts.push('overlay');
			} else {
				this.contexts.getContext('default').activate();
			}
			this.currentLinkManager.update();
			const hash = location.hash;
			if (hash.length) {
				this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
			}
		};

		this.pages.initCurrent().then(() => {
			if (this.loader) {
				this.loader.hide().then(start);
			} else {
				start();
			}
		});
	}

}

export default FrontendApp;
