export default function (pageName, pageParams, pageClasses, applications, form, appformDescriptions, introText) {
	return `
		<div ${this.html.classAttr(pageClasses)} ${this.html.dataAttr({page: pageName, params: pageParams})}>
			<div ${this.html.classAttr('blocks')}>
				<section ${this.html.classAttr('blocks__block', 'block', 'block--colorSchemeDefault')}>
					<div ${this.html.classAttr('block__content')}>

						<div ${this.html.classAttr('block__pageTitle')}>
							<h2>${this.text.get('application/applicationsPageTitle')}</h2>
						</div>
						${this.cond(introText.length, () => `
							<div ${this.html.classAttr('block__text', 'ugc')} data-kfn-intro-text>
								${introText}
							</div>
						`)}
					</div>
				</section>

				${this.cond(form, () => `
					<section ${this.html.classAttr('blocks__block', 'block', 'block--colorSchemeAlternative')}>
						<div ${this.html.classAttr('block__content')}>

							<div ${this.html.classAttr('page__form')}>
								${this.render('form/formOpen', form, 'application/add',	['form--add'], {})}
									${this.render('form/fields', form.fields)}
									<div ${this.html.classAttr('applications__appformDescriptions')}>
										${this.loop(appformDescriptions, (description, id) => this.html.element({
											tag: 'div',
											classes: ['applications__appformDescription'],
											data: {appformDescription: id},
											content: description
										}))}
									</div>
									${this.cond('actions' in form, () => this.render('form/actions', form.actions))}
								${this.render('form/formClose')}
							</div>

						</div>
					</section>
				`)}

				<section ${this.html.classAttr('blocks__block', 'block', 'block--colorSchemeDefault')}>
					<div ${this.html.classAttr('block__content')}>
						${applications.length ? `
							<div ${this.html.classAttr('page__applications', 'applications')}>
								<ul ${this.html.classAttr('applications__items')}>
									${this.loop(applications, (application) => {
										application.test = +application.test;
										application.takenover = +application.takenover;
										application.openingDiff = +application.openingDiff;
										application.closingDiff = +application.closingDiff;
										application.submitted = +application.submitted;
										application.contractEnabled = +application.contractEnabled;
										return `
										<li ${this.html.classAttr('applications__item')} ${this.html.dataAttr({application: application, applicationItem: application.id})}>
											<div ${this.html.classAttr('applications__itemTitle')}>
												<span ${this.html.classAttr('applications__itemName')}>
													${this.escape(application.title)}
												</span>
												<span ${this.html.classAttr('applications__itemAppform')}>
													${this.escape(application.appformTitle)}
												</span>
											</div>
											<div ${this.html.classAttr('applications__itemProgress')}>
												${application.submitted ? `
													<span ${this.html.classAttr('applications__submittedIcon')}>${this.html.svgIcon('check')}</span>
												` : `
													${Math.round(application.fieldsFilledCount / application.fieldsRequiredCount * 100)}%
												`}

											</div>
											<div ${this.html.classAttr('applications__itemActions')}>
												${this.cond(application.test || application.contractEnabled, () => `
													<a ${this.html.classAttr('applications__itemAction', 'button', 'button--seamless')} href="${this.url.getUrl(pageParams.contractUrl, application)}">
														<span ${this.html.classAttr('button__label')}>${this.text.get('application/contract')}</span>
													</a>
												`)}
												${this.cond(application.test || application.takenover || (!application.submitted && application.openingDiff > 0 && application.closingDiff > 0), () => `
													<a ${this.html.classAttr('applications__itemAction', 'button', 'button--seamless')} href="${this.url.getUrl(pageParams.editUrl, application)}" ${this.html.dataAttr({applicationClosing: application.test ? 0 : application.closingDiff})}>
														<span ${this.html.classAttr('button__label')}>${this.text.get('application/edit')}</span>
													</a>
												`)}
												<a ${this.html.classAttr('applications__itemAction', 'button', 'button--seamless')} href="${this.url.getUrl(pageParams.previewUrl, application)}">
													<span ${this.html.classAttr('button__label')}>${this.text.get('application/preview')}</span>
												</a>
												${this.cond(!application.submitted, () => `
													<button ${this.html.classAttr('applications__itemAction', 'button', 'button--seamless')} ${this.html.dataAttr({action: 'deleteApplication', id: application.id})}>
														<span ${this.html.classAttr('button__label')}>${this.text.get('application/delete')}</span>
													</button>
												`)}
											</div>
										</li>
										`;
									})}
								</ul>
							</div>
							` : `
							<div ${this.html.classAttr('block__text')}>
								<p>${this.text.get('application/noApplications')}</p>
							</div>
						`}
					</div>
				</section>
			</div>
		</div>
	`;
}
