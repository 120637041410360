import ApiPage from '../api-page';


class ProfilePage extends ApiPage {

	onLoad() {
		return super.onLoad().then(() => {
			this.form = this.getComponent('Form');
			this.listeners.submit = this.events.on(this.element, 'form:submit', this.onFormSubmit.bind(this));
		});
	}


	onFormSubmit(event) {
		this.form.disableSubmit();
		const values = this.form.getValue();
		this.api.execute('account/editProfile', {element: values}).then((response) => {
			if (response.isSuccess()) {
				this.notify({type: 'success', text: this.locale.get('account/updated')});
				this.form.setValue(response.output.element, true);
				// const accountManager = this.components.getComponent(this.root, 'AccountManager');
				// if (accountManager) {
				// 	this.updateAccount({name: response.output.element.name});
				// }
			} else {
				if (response.isNotValid()) {
					this.form.setValueAndErrors(response.output.element);
					console.log('not valid', response);
				} else if (response.isNotUpdated() && 'duplicated' in response.output) {
					this.form.getField('email').setErrors([this.getText('account/duplicated')]);
				}
			}
			return response;
		}).catch((error) => {
			console.log('submit error', error);
		}).then(() => {
			this.form.enableSubmit();
		});
	}

}


export default ProfilePage;
