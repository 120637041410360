export default function (section, index, current) {
	return `
		<li ${this.html.classAttr('applicationTabs__item', 'applicationTab', current ? 'js-current' : '')} ${this.html.dataAttr({applicationTab: String(section.id)})}>
			<button ${this.html.classAttr('applicationTab__action')} type="button" ${this.html.dataAttr({goTo: String(section.id)})}>
				<span ${this.html.classAttr('applicationTab__label')}>
					<span ${this.html.classAttr('applicationTab__index')}>${index + 1}.</span>
					<span ${this.html.classAttr('applicationTab__name')}>${this.escape(section.title)}</span>
				</span>
				<span ${this.html.classAttr('applicationTab__progress')} ${this.html.dataAttr({applicationTabProgress: String(section.id)})}></span>
			</button>
		</li>
	`;
}
