import picturefill from 'picturefill';
import AsyncPicture from './async-picture';


class AsyncPictureLegacy extends AsyncPicture {

	processImg() {
		picturefill({
			reevaluate: true,
			elements: [this.img]
		});
		super.processImg();
	}

}

export default AsyncPictureLegacy;
