import formConstants from './_constants';


const baseFieldMixin = (Base = class Empty {}) => class extends Base {

	prepareBaseField({
		name,
		type,
		invalidClass = 'invalid',
		errorTpl
	} = {}) {
		this.name = name;
		this.type = type;
		this.invalidClass = invalidClass;
		this.errorTpl = errorTpl;
		this.errorsElement = this.element.querySelectorAll(this.dataSelector(formConstants.errorsSelectorAttribute));
		if (this.errorsElement.length) {
			this.errorsElement = this.errorsElement[this.errorsElement.length - 1];
		} else {
			this.errorsElement = null;
		}
		this.defaultValue = this.getValue();
	}


	injectTemplate(template) {
		this.template = template;
	}


	clearBaseField() {
	}


	getName() {
		return this.name;
	}


	setName(name) {
		this.name = name;
		return this;
	}


	getType() {
		return this.type;
	}


	canFocus() {
		return false;
	}


	setValue(value, resetErrors = false) {
		const oldChangeEnabled = this.changeEnabled;
		this.changeEnabled = false;
		this.writeValue(value);
		this.changeEnabled = oldChangeEnabled;
		if (resetErrors) {
			this.resetErrors();
		}
		return this;
	}


	getValue() {
		return this.readValue();
	}


	resetValue() {
		this.setValue(this.defaultValue);
		return this;
	}


	resetErrors() {
		this.setErrors([]);
		return this;
	}


	reset() {
		this.resetValue();
		this.resetErrors();
		return this;
	}


	setErrors(errors = []) {
		return this.setOwnErrors(errors);
	}


	setOwnErrors(errors = []) {
		this.classList(this.element).toggle(this.invalidClass, errors.length > 0);
		this.errorsElement.innerHTML = this.template.loop(errors, (error) => this.template.render(this.errorTpl, error));
		return this;
	}


	setValueAndErrors(data) {
		if ('value' in data) {
			this.setValue(data.value);
		}
		if ('errors' in data) {
			this.setErrors(data.errors);
		}
		return this;
	}


	// methods to be overriden

	writeValue(value) {}

	readValue() {}

};


export default baseFieldMixin;
