class SlideshowAutoplayer {

	constructor() {
		this.timer = null;
		this.playing = false;
		this.duration = 0;
	}


	setup(slideshow, defaultDuration) {
		this.slideshow = slideshow;
		this.duration = defaultDuration;
		for (const slide of slideshow.getSlides()) {
			slide.setDefaultDuration(defaultDuration);
		}
	}


    play() {
		if (!this.playing) {
			this.playing = true;
			this.resetTimer();
		}
		return this;
    }


    pause() {
		if (this.playing) {
			this.playing = false;
			this.resetTimer();
		}
	}


	toggle() {
		const method = this.playing ? 'pause' : 'play';
		return this[method]();
	}


	resetTimer() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		if (this.playing) {
			this.duration = this.slideshow.getCurrentSlide().getDuration();
			this.timer = setTimeout(this.onTimerTrigger.bind(this), this.duration * 1000);
		} else {
			this.timer = null;
		}
		return this;
	}


	getNextSlide() {
		const slidesCount = this.slideshow.getSlidesCount();
		const currentIndex = this.slideshow.getCurrentIndex();
		if (slidesCount > 1 && (this.slideshow.dataAttr().get('circular') || currentIndex < slidesCount - 1)) {
			const nextIndex = (currentIndex + 1) % slidesCount;
			return this.slideshow.getSlides()[nextIndex];
		}
		return null;
	}


	onTimerTrigger() {
		const nextSlide = this.getNextSlide();
		if (nextSlide && this.playing) {
			this.pause();
			this.slideshow.goTo(nextSlide).then(() => {
				this.play();
			});
		}
	}


	destroy() {
		this.pause();
	}

}

export default SlideshowAutoplayer;
