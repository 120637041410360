import Application from './application';
import ApplicationTabs from './application-tabs';
import ApplicationSections from './application-sections';
import ApplicationNav from './application-nav';
import CharsCounters from './chars-counters';


export default (di) => {
	di
		.setType({
			type: Application, name: 'Application', parent: 'PageComponent', mixins: ['apiMixin', 'notificationsMixin', 'localeMixin'], setters: {
				injectNavigation: di.lazyGet('navigation/navigation'),
				injectCharsCounters: di.lazyNew('CharsCounters')
			}
		})
		.setType({type: ApplicationTabs, name: 'ApplicationTabs', parent: 'PageComponent'})
		.setType({type: ApplicationSections, name: 'ApplicationSections', parent: 'PageComponent'})
		.setType({type: ApplicationNav, name: 'ApplicationNav', parent: 'PageComponent'})
		.setType({type: CharsCounters, name: 'CharsCounters', mixins: ['domMixin']})
		;
};
