import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {getScrollTop} from '../utils/get-scroll';
import {waitFrame} from '../utils/wait';
import getTransitionDuration from '../utils/get-transition-duration';
import PageComponent from '../component/page-component';

gsap.registerPlugin(ScrollToPlugin);


class Collapsable extends PageComponent {

	constructor({
		root,
		element,
		collapsedClass = 'collapsed',
		wrapperAttribute = 'collapsableWrapper',
		keepAttribute = 'collapsableKeep',
		modeDescendants = false,
		removeNegativeMargins = true,
		useCssTransition = true,
		scroll = false,
		scrollDuration = 0.5,
		beforeToggleEvent = 'content:beforeresize',
		toggleEvent = 'content:resize',
		options = {
			focus: true
		}
	}) {
		super({root: root, element: element});
		this.defaults.collapsedClass = collapsedClass;
		this.defaults.wrapperAttribute = wrapperAttribute;
		this.defaults.keepAttribute = keepAttribute;
		this.defaults.modeDescendants = modeDescendants;
		this.defaults.removeNegativeMargins = removeNegativeMargins;
		this.defaults.useCssTransition = useCssTransition;
		this.defaults.scroll = scroll;
		this.defaults.scrollDuration = scrollDuration;
		this.defaults.beforeToggleEvent = beforeToggleEvent;
		this.defaults.toggleEvent = toggleEvent;
		this.defaults.options = options;
		this.collapsed = null; // will change to a boolean after prepare()
		this.wrapper = null;
		this.busy = false;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.options = data.options;
		this.modeDescendants = data.modeDescendants;
		if (!this.modeDescendants) {
			if (data.wrapperAttribute in data) {
				this.wrapper = this.root.querySelector(this.dataSelector('id', data.wrapperAttribute));
			}
			if (!this.wrapper) {
				this.wrapper = this.element.parentNode;
			}
		} else {
			this.wrapper = this.element;
			this.keepElements = this.element.querySelectorAll(this.dataSelector(data.keepAttribute));
		}

		this.collapsed = this.classList(this.element).contains(data.collapsedClass);
	}


	collapse(options = {}) {
		return this.toggle(true, options);
	}


	expand(options = {}) {
		return this.toggle(false, options);
	}


	toggle(value = null, options = {}) {
		if (this.busy) {
			return Promise.resolve();
		}
		const data = this.dataAttr().getAll();
		value = value === null ? !this.collapsed : !!value;
		if (value === this.collapsed) {
			return Promise.resolve();
		} else {
			this.busy = true;
			const beforeEvent = this.events.trigger(this.element, data.beforeToggleEvent, {component: this, collapsed: this.collapsed, intention: value});
			if (beforeEvent.defaultPrevented) {
				this.busy = false;
				return Promise.resolve();
			}
			let promise;
			options = Object.assign({}, this.options, options);

			if (this.modeDescendants) {
				const currentHeight = this.getNodeHeight(this.element, data.removeNegativeMargins);
				this.element.style.height = currentHeight + 'px';
				console.log('current height', currentHeight, value);
				if (!value) {
					promise = waitFrame().then(() => {
						this.classList(this.element).toggle(data.collapsedClass, value);
						return waitFrame();
					}).then(() => {
						const finalHeight = this.element.scrollHeight;
						this.element.style.height = finalHeight + 'px';
						this.collapsed = value;
						return this.onTransitionEnd(this.element);
					});
				} else {
					let contentBottom = 0;
					const elementRect = this.element.getBoundingClientRect();
					for (const keepElement of this.keepElements) {
						const rect = keepElement.getBoundingClientRect();
						if (rect.bottom > contentBottom) {
							contentBottom = rect.bottom;
						}
					}
					const finalHeight = contentBottom - elementRect.top;
					console.log('final Height', finalHeight);
					promise = waitFrame().then(() => {
						this.element.style.height = finalHeight + 'px';
						return this.onTransitionEnd(this.element);
					}).then(() => {
						this.classList(this.element).toggle(data.collapsedClass, value);
						this.collapsed = value;
						return waitFrame();
					});
				}
			} else {
				const diff = this.getWrapperHeightDiff(value, data.removeNegativeMargins);
				if (data.useCssTransition) {
					promise = waitFrame().then(() => {
						this.wrapper.style.height = diff.currentHeight + 'px';
						return waitFrame();
					}).then(() => {
						this.wrapper.style.height = diff.newHeight + 'px';
						this.classList(this.element).toggle(data.collapsedClass, value);
						this.element.setAttribute('aria-hidden', value);
						this.collapsed = value;
						if (this.collapsed) {
							let scroll = data.scroll;
							if (scroll) {
								if (scroll === true) {
									scroll = this.content.getBoundingClientRect().top;
								}
								scroll += getScrollTop();
								gsap.to(window, {duration: data.scrollDuration, scrollTo: {y: scroll, autoKill: true}});
							}
						}
						return this.onTransitionEnd(this.wrapper);
					});
				} else {
					promise = new Promise((resolve) => {
						gsap.fromTo(
							this.wrapper,
							{height: diff.currentHeight + 'px'},
							{duration: this.getDuration(), transition: 'none', height: diff.newHeight + 'px', onComplete: () => resolve()}
						);
						this.classList(this.element).toggle(data.collapsedClass, value);
						this.element.setAttribute('aria-hidden', value);
						this.collapsed = value;
					});
				}
			}
			return promise.then(() => {
				this.wrapper.style.removeProperty('height');
				this.wrapper.style.removeProperty('transition');
				this.events.trigger(this.element, data.toggleEvent, {component: this, collapsed: this.collapsed});
				if (!this.collapsed && options.focus) {
					this.element.focus({preventScroll: true});
				}
				this.busy = false;
			});
		}
	}


	isBusy() {
		return this.busy;
	}


	isCollapsed() {
		return this.collapsed;
	}


	getNodeHeight(element, removeNegativeMargins = false) {
		let height = element.getBoundingClientRect().height;
		if (removeNegativeMargins) {
			const style = getComputedStyle(element);
			const topMargin = parseFloat(style.marginTop);
			if (!isNaN(topMargin) && topMargin < 0) {
				height += topMargin;
			}
			const bottomMargin = parseFloat(style.marginBottom);
			if (!isNaN(bottomMargin) && bottomMargin < 0) {
				height += bottomMargin;
			}
		}
		return height;
	}


	getWrapperHeightDiff(value, removeNegativeMargins = null) {
		if (removeNegativeMargins === null) {
			removeNegativeMargins = this.dataAttr().get('removeNegativeMargins');
		}
		const currentWrapperHeight = this.getNodeHeight(this.wrapper, removeNegativeMargins);
		const contentHeight = this.getNodeHeight(this.element, removeNegativeMargins);
		const newWrapperHeight = Math.max(0, currentWrapperHeight + (value ? -contentHeight : contentHeight));
		return {currentHeight: currentWrapperHeight, newHeight: newWrapperHeight};
	}


	getDuration(element = null, seconds = true) {
		if (!element) {
			element = this.wrapper;
		}

		return getTransitionDuration(element, null, true, seconds);
	}


	enableCssTransition() {
		this.dataAttr().set('useCssTransition', true);
	}


	disableCssTransition() {
		this.dataAttr().set('useCssTransition', false);
	}

}


export default Collapsable;
