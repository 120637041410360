import {isString} from '../utils/types';
import TemplateHelper from './template-helper';


class HtmlHelper extends TemplateHelper {

	constructor({classListParser, dataAttrParser, iconFilesLoader}) {
		super();
		this.classListParser = classListParser;
		this.dataAttrParser = dataAttrParser;
		this.iconFilesLoader = iconFilesLoader;
		this.elementsStack = [];
    }


	dataAttr(name, value = undefined) {
		return this.dataAttrParser.buildString(name, value);
	}


	jsClasses(...names) {
		if (names.length === 0) {
			return '';
		}
		if (names.length === 1 && Array.isArray(names[0])) {
			names = names[0];
		}
		return this.classListParser.buildString(names);
	}


	classes(...names) {
		if (names.length === 0) {
			return '';
		}
		if (names.length === 1 && Array.isArray(names[0])) {
			names = names[0];
		}
		return this.classListParser.buildBaseString(names);
	}


	classAttr(...names) {
		if (names.length === 0) {
			return '';
		}
		return this.attribute('class', this.classes(...names));
	}


	attribute(name, value) {
		return name + '="' + this.template.escape(value) + '"';
	}


	attributes(attributes) {
		return this.template.loop(attributes, (value, name) => this.attribute(name, value), ' ');
	}

	element({tag, classes = [], jsClasses = [], data = {}, attributes = {}, content = null}) {
		let output = this.open({
			tag: tag,
			classes: classes,
			jsClasses: jsClasses,
			data: data,
			attributes: attributes,
			close: content === null
		});
		if (content !== null) {
			output += content + this.close();
		}
		return output;
	}


	open({tag, classes = [], jsClasses = [], data = {}, attributes = {}, close = false}) {
		if (!close) {
			this.elementsStack.push(tag);
		}
		let classAttr = ('class' in attributes ? attributes.class : []);
		if (!Array.isArray(classAttr)) {
			classAttr = [classAttr];
		}
		classAttr = classAttr.concat(this.classes(classes), this.jsClasses(jsClasses));
		attributes.class = classAttr.join(' ').trim();
		return '<' + tag +
			(Object.keys(attributes).length ? ' ' + this.attributes(attributes) : '') +
			(Object.keys(data).length ? ' ' + this.dataAttr(data) : '') +
			(close ? ' />' : '>')
		;
	}


	close() {
		let output = '';
		if (this.elementsStack.length) {
			const name = this.elementsStack.pop();
			output = '</' + name + '>';
		}
		return output;
	}


	tag(name, attributes = {}, dataAttributes = {}, content = false) {
		return '<' + name +
			(Object.keys(attributes).length ? ' ' + this.attributes(attributes) : '') +
			(Object.keys(dataAttributes).length ? ' ' + this.dataAttr(dataAttributes) : '') +
			(content === false ? ' />': '>' + content + '</' + name + '>')
		;
	}


	svgIcon(file, id, attributes = {}, data = {}) {
		const info = this.getSvgIconInfo(file, id);
		let url = '';
		if (!isString(id)) {
			if (attributes !== undefined) {
				data = attributes;
			}
			if (id !== undefined) {
				attributes = id;
			}
			id = file;
		} else {
			url = this.iconFilesLoader.getFileUrl(file);
		}
		url += '#' + id;
		return this.svgMarkup(url, info, attributes, data);
	}


	getSvgIconInfo(file, id = undefined) {
		return this.iconFilesLoader.getIconInfo(file, id);
	}


	svgMarkup(url, info, attributes = {}, data = {}) {
		const canvasTag = this.tag('canvas', {width: info.width, height: info.height}, {}, '');
		const useTag = this.tag('use', {'xlink:href': url}, {}, '');
		const svgTag = this.tag('svg', {viewBox: info.viewBox, title: info.title}, {}, useTag);
		data.type = 'svg';
		return this.tag(
			'span',
			attributes,
			data,
			canvasTag + svgTag
		);
	}

}

export default HtmlHelper;
