import ClientRenderedPageLoader from '../../../common/page/client-rendered-page-loader';


class EditApplicationPageLoader extends ClientRenderedPageLoader {

	requestPage(request) {
		const requestParams = this.prepareRequestParams(request);
		return this.api.execute('application/get', {element: requestParams}).then((response) => {
			if (response.isSuccess()) {
				const content = this.template.render(
					'page/application/edit',
					'editApplication',
					requestParams,
					['page', 'page--applicationEdit'],
					response.output.applicationId,
					response.output.application,
					response.output.sections,
					response.output.fields,
					response.output.form,
					requestParams.backUrl,
					requestParams.previewUrl
				);
				return {content: content, title: requestParams.title};
			} else {
				setTimeout(() => {
					this.navigation.redirect(requestParams.backUrl);
				}, 10);
			}
			return false;
		}).catch((error) => {
			console.log('error', error);
			return false;
		});
	}


	prepareRequestParams(request) {
		const requestParams = {};
		for (const name in request.params) {
			if (request.params.hasOwnProperty(name) && request.params[name] !== undefined) {
				requestParams[name] = request.params[name];
			}
		}
		return requestParams;
	}

}


export default EditApplicationPageLoader;
