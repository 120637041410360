import formConstants from './_constants';


const interactiveFieldMixin = (Base = class Empty {}) => class extends Base {


	prepareInteractiveField({eventsEnabled = true, asyncEvents = true} = {}) {
		this.eventsEnabled = eventsEnabled;
		this.asyncEvents = asyncEvents;
		this.input = this.getInput();
		this.enabled = !this.classList(this.element).contains(formConstants.disabledClass);
		this.visible = !this.classList(this.element).contains(formConstants.hiddenClass);
		this.initEvents();
	}


	canFocus() {
		return true;
	}


	initEvents() {
		this.initFocusEvent();
		this.initBlurEvent();
		this.initChangeEvent();
	}


	enableEvents() {
		this.eventsEnabled = true;
		return this;
	}


	disableEvents() {
		this.eventsEnabled = false;
		return this;
	}


	initFocusEvent() {
		this.listeners.focus = this.events.on(this.element, 'focusin', this.onFocus.bind(this));
	}


	initBlurEvent() {
		this.listeners.blur = this.events.on(this.element, 'focusout', this.onBlur.bind(this));
	}


	initChangeEvent() {
		this.listeners.change = this.events.on(this.element, 'change', this.onChange.bind(this));
	}


	onFocus(event) {
		if (this.eventsEnabled) {
			this.triggerEvent('focus', event);
		}
	}


	onBlur(event) {
		if (this.eventsEnabled) {
			this.triggerEvent('blur', event);
		}
	}


	onChange(event) {
		if (this.eventsEnabled) {
			this.triggerEvent('change', event);
		}
	}


	focus(value, suppressEvent = false) {
		const oldEventsEnabled = this.eventsEnabled;
		if (suppressEvent) {
			this.eventsEnabled = false;
		}
		this.setFocus();
		this.eventsEnabled = oldEventsEnabled;
		return this;
	}


	blur(value, suppressEvent = false) {
		const oldEventsEnabled = this.eventsEnabled;
		if (suppressEvent) {
			this.eventsEnabled = false;
		}
		this.setBlur();
		this.eventsEnabled = oldEventsEnabled;
		return this;
	}


	triggerEvent(type, event) {
		const callback = () => {
			if (this.element) {
				this.events.trigger(this.element, 'field:' + type, {component: this, originalEvent: event});
			}
		};
		if (this.asyncEvents) {
			setTimeout(callback, 0);
		} else {
			callback();
		}
		return this;
	}


	enable() {
		return this.toggleEnabled(true);
	}


	disable() {
		return this.toggleEnabled(false);
	}


	toggleEnabled(value = null) {
		if (value === null) {
			value = !this.enabled;
		}
		if (value !== this.enabled) {
			this.enabled = value;
			this.classList(this.element).toggle(formConstants.disabledClass, !this.enabled);
			if (this.enabled) {
				this.enableInput();
			} else {
				this.disableInput();
			}
		}
		return this;
	}


	show() {
		return this.toggleVisibility(true);
	}


	hide() {
		return this.toggleVisibility(false);
	}


	toggleVisibility(value = null) {
		if (value === null) {
			value = !this.visible;
		}
		if (value !== this.visible) {
			this.visible = value;
			this.classList(this.element).toggle(formConstants.hiddenClass, !this.visible);
		}
		return this;
	}


	// to be overriden
	getInput() {}

	setFocus() {}

	setBlur() {}

	enableInput() {}

	disableInput() {}

};


export default interactiveFieldMixin;
