import FrontendAccount from './frontend-account';
import FrontendAccountManager from './frontend-account-manager';


export default (di) => {
	di
		.setType({type: FrontendAccount, name: 'FrontendAccount', parent: 'Account'})
		.set('account/account', di.lazyNew('FrontendAccount'))
		.setType({
			type: FrontendAccountManager, name: 'FrontendAccountManager', parent: 'AccountManager', params: {
				registerUrl: di.lazyValue('account/registerUrl')
			}
		})
		;
};
