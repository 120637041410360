import SearchEngine from './search-engine';


class ApiSearchEngine extends SearchEngine {

	constructor({api, action = 'search'}) {
		super();
		this.api = api;
		this.action = action;
	}


	search(params) {
		return this.api.execute('search/search', {params: params}).then((response) => {
			if (response.isSuccess()) {
				return response.output;
			} else {
				console.error('search not valid', response);
			}
			return {};
		}).catch((error) => {
			console.error('search error', error);
		});
	}


	track(params) {
		// const params = {search: search, tags: tags, page: page, source: location.pathname, extra: extra};
		return this.api.execute('search/track', {params: params});
	}


}


export default ApiSearchEngine;
