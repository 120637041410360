// based on https://stackoverflow.com/questions/16055275/html-templates-javascript-polyfills

export default function getTemplateContent(template) {
	if (!('content' in template)) {
		const children = template.childNodes;
		const docContent = document.createDocumentFragment();
		while(children[0]) {
			docContent.appendChild(children[0]);
		}
		template.content = docContent;
	}
	return template.content;
}
