import autosize from 'autosize';
import substringCount from 'locutus/php/strings/substr_count';
import formatNumber from 'locutus/php/strings/number_format';
import {trim} from '../utils/string';
import getTemplateContent from '../utils/template-content-polyfill';
import PageComponent from '../component/page-component';


const defaultValueAttr = 'defaultValue';
const descAttr = 'desc';
const valueAttr = 'value';
const rowAttr = 'row';
const rowsAttr = 'rows';
const rowTemplateAttr = 'rowTemplate';
const sumAttr = 'sum';
const numberFormatAttr = 'numberFormat';
const readonlyAttr = 'readonly';
const placeholderAttr = 'firstLinePlaceholder';
const tabularDataRowsSep = /(?:\n\r)|(?:\r\n)|(?:\n)|(?:\r)/;
const tabularDataColsSep = /\s*\t\s*/;
const dotAlternativeChar = '․';	// U+2024	ONE DOT LEADER



class SumTable extends PageComponent {

	constructor({
		root,
		element,
	}) {
		super({root: root, element: element});
		this.scheduledValueUpdate = false;
		this.pendingValueUpdate = false;
		this.pendingRemoteUpdates = 0;
	}


	prepare() {
		this.rowTemplate = getTemplateContent(this.element.querySelector(this.dataSelector(rowTemplateAttr))).querySelector(this.dataSelector(rowAttr));
		this.rows = this.element.querySelector(this.dataSelector(rowsAttr));
		this.sum = this.element.querySelector(this.dataSelector(sumAttr));
		this.numberFormat = this.dataAttr().get(numberFormatAttr);
		this.readonly = this.dataAttr().get(readonlyAttr);
		this.placeholder = this.dataAttr().get(placeholderAttr, '');

		if (this.numberFormat.thousandsSeparator === '.') {
			this.numberFormat.thousandsSeparator = dotAlternativeChar;
		}

		if (!this.readonly) {
			this.listeners.input = this.events.on(this.rows, 'input, textarea', 'input', this.onInput.bind(this));
			this.listeners.change = this.events.on(this.rows, 'input, textarea', 'change', this.onChange.bind(this));
			this.listeners.paste = this.events.on(this.rows, 'input, textarea', 'paste', this.onPaste.bind(this));
		}
		this.setValue(this.dataAttr().get(defaultValueAttr), true);
	}


	clear() {
	}


	onInput(event, target) {
		this.grantLastEmptyRow(target, true);
		const isTargetValue = this.dataAttr(target).has(valueAttr);
		if (isTargetValue) {
			this.scheduleValueUpdate();
		}

	}


	onChange(event, target) {
		const isTargetValue = this.dataAttr(target).has(valueAttr);
		if (isTargetValue) {
			target.value = this.formatNumber(target.value);
		}
		this.updateValue();
		this.triggerChange();
	}


	onPaste(event, target) {
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		const rawData = trim((event.clipboardData || window.clipboardData).getData('text'));
		if (rawData && rawData.length) {
			const data = this.getTabularData(rawData);
			if (data) {
				event.preventDefault();
				const isTargetValue = this.dataAttr(target).has(valueAttr);
				const colsNumber = data[0].length;
				let prevRow = null;
				for (let i = 0; i < data.length; i++) {
					let row = (
						i === 0 ?
						target.closest(this.dataSelector(rowAttr)) :
						this.newRow()
					);

					const descInput = row.querySelector(this.dataSelector(descAttr));
					const valueInput = row.querySelector(this.dataSelector(valueAttr));
					if (isTargetValue) {
						valueInput.value = this.formatNumber((colsNumber > 1 ? data[i][1] : data[i][0]));
						if (colsNumber > 1) {
							descInput.value = trim(data[i][0]);
						}
					} else {
						descInput.value = trim(data[i][0]);
						if (colsNumber > 1) {
							valueInput.value = this.formatNumber(data[i][1]);
						}
					}
					if (i > 0) {
						row = row.querySelector(this.dataSelector(rowAttr));
						prevRow.insertAdjacentElement('afterend', row);
						autosize(row.querySelector('textarea'));
					}
					prevRow = row;
				}
				this.grantLastEmptyRow(target, false);
				this.updateValue();
				this.triggerChange();
			}
		}
	}


	scheduleValueUpdate() {
		this.scheduledValueUpdate = true;
		const tickUpdate = () => {
			this.updateValue();
			if (this.scheduledValueUpdate) {
				this.scheduledValueUpdate = false;
				requestAnimationFrame(tickUpdate);
			} else {
				this.pendingValueUpdate = false;
			}
		};
		if (!this.pendingValueUpdate) {
			this.pendingValueUpdate = true;
			requestAnimationFrame(tickUpdate);
		}
	}


	grantLastEmptyRow(target, fixFocus = true) {
		const isTargetDesc = this.dataAttr(target).has(descAttr);
		const rows = this.rows.querySelectorAll(this.dataSelector(rowAttr));
		const len = rows.length;
		let last = len - 1;
		if (this.isRowEmpty(rows[last])) {
			while (last - 1 >= 0 && this.isRowEmpty(rows[last - 1])) {
				if (fixFocus) {
					rows[last - 1].querySelector(this.dataSelector(isTargetDesc ? descAttr : valueAttr)).focus();
				}
				this.rows.removeChild(rows[last]);
				last--;
			}
		} else {
			const newRow = this.newRow();
			this.rows.appendChild(newRow);
			autosize(this.rows.querySelector(this.dataSelector(rowAttr) + ':last-child textarea'));
		}
	}


	getTabularData(rawData) {
		const rows = rawData.split(tabularDataRowsSep);
		const data = [];
		let colsNumber = null;
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			const cols = row.split(tabularDataColsSep);
			if (colsNumber === null) {
				colsNumber = cols.length;
			} else if (colsNumber !== cols.length) {
				return false;
			}
			data.push(cols);
		}
		if (colsNumber === 1 && rows.length === 1) {
			return false;
		}
		return data;
	}


	updateValue() {
		const descs = this.rows.querySelectorAll(this.dataSelector(descAttr));
		const values = this.rows.querySelectorAll(this.dataSelector(valueAttr));
		this.value.rows = [];
		this.value.sum = 0;
		for (let i = 0; i < descs.length; i++) {
			const desc = trim(descs[i].value);
			const value = trim(values[i].value);
			if (desc.length > 0 || value.length > 0) {
				const valueNumber = this.unformatNumber(value);
				this.value.rows.push({desc: desc, value: valueNumber});
				if (!isNaN(valueNumber) && valueNumber !== '') {
					this.value.sum += valueNumber;
				}
			}
		}
		this.updateSum();
	}


	updateSum() {
		this.sum.value = this.formatNumber(this.value.sum);
	}


	isRowEmpty(row) {
		const descInput = row.querySelector(this.dataSelector(descAttr));
		const valueInput = row.querySelector(this.dataSelector(valueAttr));
		return (trim(descInput.value).length === 0 && trim(valueInput.value).length === 0);
	}


	formatNumber(number) {
		number = trim(String(number));
		const dotPos = number.indexOf('.');
		const commaPos = number.indexOf(',');
		if (substringCount(number, '.') > 1 || (dotPos > -1 && commaPos > -1 && dotPos < commaPos)) {
			number = number.replace(/\./g, '');
		} else if (substringCount(number, ',') > 1 || (dotPos > -1 && commaPos > -1 && commaPos < dotPos)) {
			number = number.replace(/,/g, '');
		}
		number = number.replace(/\s+/g, '').replace(',', '.');
		number = number.replace(/[^0-9.+-]/g, '');
		number = parseFloat(number);
		if (isNaN(number)) {
			return '';
		}
		return this.numberFormat.preUnitSymbol +
			formatNumber(
				number,
				this.numberFormat.decimalsAmount,
				this.numberFormat.decimalsSeparator,
				this.numberFormat.thousandsSeparator
			) +
			this.numberFormat.postUnitSymbol;
	}


	unformatNumber(number) {
		number = number.replace(',', '.').replace(/[^0-9.+-]/g, '');
		number = parseFloat(number);
		if (isNaN(number)) {
			return '';
		}
		return number;
	}


	getValue() {
		return this.value;
	}


	setValue(value, firstTime = false) {
		if (this.pendingRemoteUpdates > 0) {
			this.pendingRemoteUpdates--;
			return;
		}

		this.value = value;
		const descs = this.rows.querySelectorAll(this.dataSelector(descAttr));
		const values = this.rows.querySelectorAll(this.dataSelector(valueAttr));

		const fragment = document.createDocumentFragment();
		// one more in order to have always an empty row at the end
		let j = 0;
		const end = (firstTime ? value.rows.length : value.rows.length - 1);
		for (let i = 0; i <= end; i++) {
			while (j < descs.length && trim(descs[j].value).length === 0 && trim(values[j].value).length === 0) {
				j++;
			}
			if (j < descs.length) {
				descs[j].value = value.rows[i].desc;
				values[j].value = this.formatNumber(value.rows[i].value);
				j++;
			} else {
				const row = (
					i < value.rows.length ?
					this.newRow(value.rows[i].desc, this.formatNumber(value.rows[i].value)) :
					this.newRow()
				);
				if (firstTime && i === 0) {
					row.querySelector(this.dataSelector(descAttr)).setAttribute('placeholder', this.placeholder);
				}
				fragment.appendChild(row);
			}
		}
		// this.rows.innerHTML = '';
		this.rows.appendChild(fragment);
		autosize(this.rows.querySelectorAll('textarea'));
		this.updateSum();
	}


	newRow(desc = '', value = '') {
		const row = this.rowTemplate.cloneNode(true);
		const descInput = row.querySelector(this.dataSelector(descAttr));
		const valueInput = row.querySelector(this.dataSelector(valueAttr));
		descInput.value = desc;
		valueInput.value = value;
		return row;
	}


	triggerChange() {
		this.pendingRemoteUpdates++;
		this.events.trigger(this.element, 'sumtable:change', {component: this, value: this.getValue()});
	}

}


export default SumTable;
