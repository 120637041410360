import FrontendApp from './frontend-app';


export default (di) => {
	// TODO: make this configurable
	const dynamicBaseUrls = {
		applications: '',
		editApplication: 'bewerbung-bearbeiten-{id}',
		previewApplication: 'bewerbung-vorschau-{id}',
		contractApplication: 'bewerbung-vertrag-{id}',
		profile: 'profil',
		register: 'anmeldung'
	};

	di
		.setType({
			type: FrontendApp, name: 'FrontendApp', parent: 'App', params: {
				requiredStyles: ['default'],
				requiredFonts: [
					// {
					// 	name: 'Futura LT W01',
					// 	options: {weight: 'normal'}
					// },
					// {
					// 	name: 'Futura LT W01',
					// 	options: {weight: 'bold'}
					// }
				]
			}, setters: {
				injectErrorListener: di.lazyGet('api/errorListener'),
				injectPages: di.lazyGet('page/pages'),
				injectPageSlots: di.lazyGet('page/slots'),
				// injectNavigationTracker: di.lazyNew('GaGtagNavigationTracker'),
				injectCurrentLinkManager: di.lazyGet('navigation/currentLinkManager'),
				injectHashScroller: di.lazyNew('HashScroller'),
				injectLocaleDynamicTranslator: di.lazyGet('locale/dynamicTranslator')
			}, postCreateCalls: [(app) => {
				const transitionRouter = di.get('router/pageTransitionRouter');
				const loaderRouter = di.get('router/pageLoaderRouter');
				const dataAttrFactory = di.get('dom/dataAttrFactory');
				const locale = di.get('locale/localeText');

				transitionRouter

					// overlay
					.append(transitionRouter.newRule().ifTrue((subject, request) =>
							(request.navigationType === 'link' && dataAttrFactory({element: request.originalTarget}).get('slotTarget') === 'overlay') ||
							(request.navigationType === 'pop' && 'slotTarget' in request.state && request.state.slotTarget === 'overlay')
						).then((subject, matches, request) => {
							// console.log('match overlay page transition');
							request.transition = 'overlay';
							return subject;
						}).setLast()
					)

					// fallback to default transition
					.append(transitionRouter.newRule().always().then((subject, matches, request) => {
							// console.log('match default page transition');
							request.transition = 'default';
							return subject;
						}).setLast()
					)
				;

				loaderRouter
					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.applications + '(\\?.+)?$', 'g').then((subject, matches, request) => {
							// console.log('match register');
							request.loader = 'applications';
							request.params.title = locale.get('application/applicationsPageTitle');
							request.params.editUrl = dynamicBaseUrls.editApplication;
							request.params.previewUrl = dynamicBaseUrls.previewApplication;
							request.params.contractUrl = dynamicBaseUrls.contractApplication;
							return subject;
						}).setLast()
					)
					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.editApplication.replace('{id}', '(?<id>[0-9]+)$'), 'g').then((subject, matches, request) => {
							// console.log('match editApplication');
							request.loader = 'editApplication';
							request.params.title = locale.get('application/editPageTitle');
							request.params.backUrl = dynamicBaseUrls.applications;
							request.params.previewUrl = dynamicBaseUrls.previewApplication;
							return subject;
						}).setLast()
					)

					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.previewApplication.replace('{id}', '(?<id>[0-9]+)$'), 'g').then((subject, matches, request) => {
							// console.log('match previewApplication');
							request.loader = 'previewApplication';
							request.params.title = locale.get('application/previewPageTitle');
							request.params.backUrl = dynamicBaseUrls.applications;
							return subject;
						}).setLast()
					)

					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.contractApplication.replace('{id}', '(?<id>[0-9]+)$'), 'g').then((subject, matches, request) => {
							console.log('match contractApplication');
							request.loader = 'contractApplication';
							request.params.title = locale.get('application/contractPageTitle');
							request.params.backUrl = dynamicBaseUrls.applications;
							return subject;
						}).setLast()
					)

					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.register + '$', 'g').then((subject, matches, request) => {
							console.log('match register');
							request.loader = 'register';
							request.params.title = locale.get('account/registerTitle');
							request.params.applications = dynamicBaseUrls.applications;
							return subject;
						}).setLast()
					)

					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.profile + '$', 'g').then((subject, matches, request) => {
							console.log('match profile');
							request.loader = 'profile';
							request.params.title = locale.get('account/profileTitle');
							return subject;
						}).setLast()
					)

					// fallback to server side rendered page
					.append(loaderRouter.newRule().always().then((subject, matches, request) => {
							console.log('match default page loader', subject);
							request.loader = 'serverRendered';
							return subject;
						}).setLast()
					)
				;
			}]
		})

		.set('app', di.lazyNew('FrontendApp'))
		;
};
