import InteractiveField from './interactive-field';
import textFieldMixin from './text-field-mixin';
import {isString} from '../utils/types';


// this is temporary
class SumField extends textFieldMixin(InteractiveField) {

	getInput() {
		if (!this.input) {
			this.input = this.getComponent('SumTable');
		}
		return this.input;
	}


	writeValue(value) {
		if (isString(value)) {
			value = JSON.parse(value);
		}
		this.getInput().setValue(value);
		return this;
	}


	readValue() {
		return this.getInput().getValue();
	}


	initChangeEvent() {
		this.listeners.change = this.events.on(this.getInput().getElement(), 'sumtable:change', this.onChange.bind(this));
	}

}


export default SumField;
