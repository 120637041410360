import DefaultPage from './default-page';
import OverlayPage from './overlay-page';
import ApiPage from './api-page';
import HomePage from './home/home-page';
import ErrorPage from './error/error-page';
import RegisterPage from './account/register-page';
import ProfilePage from './account/profile-page';
import ApplicationsPage from './application/applications-page';
import EditApplicationPage from './application/edit-application-page';
import PreviewApplicationPage from './application/preview-application-page';
import ContractApplicationPage from './application/contract-application-page';

import RegisterPageLoader from './account/register-page-loader';
import ProfilePageLoader from './account/profile-page-loader';
import ApplicationsPageLoader from './application/applications-page-loader';
import EditApplicationPageLoader from './application/edit-application-page-loader';
import PreviewApplicationPageLoader from './application/preview-application-page-loader';
import ContractApplicationPageLoader from './application/contract-application-page-loader';

import DefaultPageTransition from './default-page-transition';
import OverlayPageTransition from './overlay-page-transition';


export default (di) => {
	di
		.setType({
			type: DefaultPage, name: 'DefaultPage', parent: 'Page'})

		.setType({type: OverlayPage, name: 'OverlayPage', parent: 'DefaultPage'})
		.setType({
			type: ApiPage, name: 'ApiPage', parent: 'DefaultPage', mixins: [
				'templateMixin',
				'localeMixin',
				'accountMixin',
				'apiMixin',
				'dialogMixin',
				'notificationsMixin'
			]
		})

		.setType({type: HomePage, name: 'HomePage', parent: 'DefaultPage'})
		.setType({type: RegisterPage, name: 'RegisterPage', parent: 'ApiPage'})
		.setType({type: ProfilePage, name: 'ProfilePage', parent: 'ApiPage'})
		.setType({type: ErrorPage, name: 'ErrorPage', parent: 'DefaultPage'})

		.setType({type: ApplicationsPage, name: 'ApplicationsPage', parent: 'ApiPage'})
		.setType({type: EditApplicationPage, name: 'EditApplicationPage', parent: 'ApiPage'})
		.setType({type: PreviewApplicationPage, name: 'PreviewApplicationPage', parent: 'ApiPage'})
		.setType({type: ContractApplicationPage, name: 'ContractApplicationPage', parent: 'ApiPage'})

		.setType({type: RegisterPageLoader, name: 'RegisterPageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: ProfilePageLoader, name: 'ProfilePageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: ApplicationsPageLoader, name: 'ApplicationsPageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: EditApplicationPageLoader, name: 'EditApplicationPageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: PreviewApplicationPageLoader, name: 'PreviewApplicationPageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: ContractApplicationPageLoader, name: 'ContractApplicationPageLoader', parent: 'ClientRenderedPageLoader', mixins: ['navigationMixin', 'localeMixin', 'notificationsMixin']})

		.setType({type: DefaultPageTransition, name: 'DefaultPageTransition', parent: 'PageTransition'})
		.setType({type: OverlayPageTransition, name: 'OverlayPageTransition', parent: 'PageTransition'})
		;
};
