// import polyfills for legacy target
import 'intersection-observer';
import 'core-js/stable/object/assign';
import 'core-js/stable/symbol';
import 'core-js/stable/promise';
import 'core-js/stable/regexp';
import 'dom4';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';

svg4everybody();
picturefill();
