import VideoPlayerDriver from './video-player-driver';



/**
 * Youtube Player Driver (used internally by VideoPlayer Class)
 * Additional info on params available only for Youtube:
 * https://developers.google.com/youtube/player_parameters
 *
 * @class YoutubeVideoPlayerDriver
 * @extends {VideoPlayerDriver}
 */
class SelfHostedVideoPlayerDriver extends VideoPlayerDriver {

	createInternalPlayer(api) {
		this.sourceElements = this.element.querySelectorAll('source');
		this.sources = new Map();
		this.orientedSources = {
			landscape: new Map(),
			portrait: new Map()
		};
		this.currentOrientation = null;
		this.currentArea = null;
		for (let i = 0, end = this.sourceElements.length; i < end; i++) {
			const source = this.url[i];
			const area = (source.width !== null && source.height !== null ? source.width * source.height : 0);
			if (!this.sources.has(area)) {
				const entry =  {
					width: source.width,
					height: source.height,
					orientation: source.orientation,
					indexes: []
				};
				this.sources.set(area, entry);
				if (source.orientation !== null) {
					this.orientedSources[source.orientation].set(area, entry);
				}
			}
			this.sources.get(area).indexes.push(i);
		}
		this.hasOrientationVariants = (this.orientedSources.landscape.size > 0 && this.orientedSources.portrait.size > 0);
		this.listeners = {};
		this.status = {
			buffering: false,
			playing: false,
			wasPlaying: false,
			switching: false,
			loadingAlternative: false
		};
		this.previousTime = 0;
		this.player = this.element;

		return new Promise((resolve) => {
			this.readyTrigger = resolve;
			this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
			this.listeners.loadstart = this.events.on(this.element, 'loadstart', this.onLoadStart.bind(this));
			this.listeners.canplay = this.events.on(this.element, 'canplay', this.onCanPlay.bind(this));
			this.listeners.canplaythrough = this.events.on(this.element, 'canplaythrough', this.onCanPlayThrough.bind(this));
			this.listeners.loadeddata = this.events.on(this.element, 'loadeddata', this.onLoadedData.bind(this));
			this.listeners.playing = this.events.on(this.element, 'playing', this.onPlaying.bind(this));
			this.listeners.pause = this.events.on(this.element, 'pause', this.onPause.bind(this));
			this.listeners.ended = this.events.on(this.element, 'ended', this.onEnded.bind(this));
			this.listeners.error = this.events.on(this.element, 'error', this.onError.bind(this));
			this.listeners.ratechange = this.events.on(this.element, 'ratechange', this.onRateChange.bind(this));
			this.listeners.seeking = this.events.on(this.element, 'seeking', this.onSeeking.bind(this));
			this.listeners.seeked = this.events.on(this.element, 'seeked', this.onSeeked.bind(this));
			this.listeners.waiting = this.events.on(this.element, 'waiting', this.onWaiting.bind(this));
			this.loadOptimalSources();
		});
	}


	destroy() {
		if ('listeners' in this) {
			for (const name in this.listeners) {
				if (this.listeners.hasOwnProperty(name) && this.listeners[name]) {
					this.listeners[name].destroy();
				}
			}
			this.listeners = {};
		}
	}



	onResize(event) {
		if (this.readyTrigger === null) {
			this.resize();
		}
	}


	resize() {
		this.status.wasPlaying = this.status.playing;
		this.previousTime = this.player.currentTime;
		this.status.switching = this.loadOptimalSources();
	}


	restoreAfterResize() {
		if (this.status.loadingAlternative) {
			this.status.loadingAlternative = false;
			this.status.switching = false;
			this.player.currentTime = this.previousTime;
			if (this.status.wasPlaying) {
				this.player.play();
				this.status.wasPlaying = false;
			}
		}

	}

	/**
	 * @returns {Promise}: resolved with the Driver itself
	 */
	load() {
		if (this.promise === null) {
			this.promise = new Promise((resolve) => {
				this.createInternalPlayer(null).then(() => {
					resolve(this);
				});
			});
		}
		return this.promise;
	}


	loadOptimalSources() {
		const elementRect = this.element.getBoundingClientRect();
		const elementWidth = elementRect.width;
		const elementHeight = elementRect.height;
		const elementRatio = elementWidth / elementHeight;
		const elementArea = elementWidth * elementHeight;
		const elementOrientation = (elementRatio >= 1 ? 'landscape' : 'portrait');
		let smallestDiff = Infinity;
		let closestArea = null;
		let selectedEntry = null;
		// if we have different versions for portrait and landscape we check only sources with the same orientation
		const sources = (this.hasOrientationVariants ? this.orientedSources[elementOrientation] : this.sources);
		for (const [area, entry] of sources) {
			// we select the sources with the closest area (width * height) to the video element, if the sizes were provided in the file name
			const diff = (area === 0 ? 0 : Math.abs(area - elementArea));
			if (diff < smallestDiff) {
				smallestDiff = diff;
				closestArea = area;
				selectedEntry = entry;
			}
		}

		// when do we switch sources?
		// - the first time we are loading the video OR
		// - the orientation changed OR
		// - we are upscaling to a higher resolution
		if (closestArea !== null && (this.currentOrientation !== elementOrientation || this.currentArea === null || closestArea > this.currentArea)) {
			// console.log('size switch', this.currentOrientation, elementOrientation, this.currentArea, closestArea);
			this.currentArea = closestArea;
			this.currentOrientation = elementOrientation;
			for (let i = 0, end = this.sourceElements.length; i < end; i++) {
				const sourceElement = this.sourceElements[i];
				if (selectedEntry.indexes.indexOf(i) >= 0) {
					sourceElement.setAttribute('src', this.dataAttr(sourceElement).get('src'));
				} else {
					sourceElement.removeAttribute('src');
				}
			}
			this.element.load();
			return true;
		}
		return false;
	}


	onLoadStart(event) {
		if (this.status.switching) {
			this.status.loadingAlternative = true;
		}
	}


    onCanPlay(event) {
		if (this.status.loadingAlternative) {
			this.restoreAfterResize();
		}
		if (this.status.buffering) {
			this.status.buffering = false;
			this.controller.triggerEvent(this.controller.eventNames.bufferend);
		}
    }


    onCanPlayThrough(event) {
		if (this.status.loadingAlternative) {
			this.restoreAfterResize();
		}
		if (this.status.buffering) {
			this.status.buffering = false;
			this.controller.triggerEvent(this.controller.eventNames.bufferend);
		}
    }


    onLoadedData(event) {
		if (this.readyTrigger) {
			this.readyTrigger();
			this.readyTrigger = null;
		} else if (this.status.loadingAlternative) {
			this.restoreAfterResize();
		}

    }


    // onLoadedMetadata(event) {
	// 	console.log(event.type, event);
    // }


    onPlaying(event) {
		if (this.status.buffering) {
			this.status.buffering = false;
			this.controller.triggerEvent(this.controller.eventNames.bufferend);
		}
		this.status.playing = true;
		this.controller.triggerEvent(this.controller.eventNames.playing, this.getEventData());
    }


    onPause(event) {
		this.status.playing = false;
		this.controller.triggerEvent(this.controller.eventNames.paused, this.getEventData());
    }


    onEnded(event) {
		this.status.playing = false;
		this.controller.triggerEvent(this.controller.eventNames.ended, this.getEventData());
    }


    onError(event) {
		this.status.playing = false;
		this.controller.triggerEvent(this.controller.eventNames.error, {
			message: this.player.error.message,
			errorCode: this.player.error.code
		});
    }


    onRateChange(event) {
		this.controller.triggerEvent(this.controller.eventNames.speedchange, {speed: this.player.playbackRate});
    }


    onSeeking(event) {
		if (!this.status.buffering) {
			this.status.buffering = true;
			this.controller.triggerEvent(this.controller.eventNames.bufferstart);
		}
    }


    onSeeked(event) {
		if (this.status.buffering) {
			this.status.buffering = false;
			this.controller.triggerEvent(this.controller.eventNames.bufferend);
		}
    }


    onWaiting(event) {
		if (!this.status.buffering) {
			this.status.buffering = true;
			this.controller.triggerEvent(this.controller.eventNames.bufferstart);
		}
    }


	play() {
		return this.player.play();
	}


	pause() {
		this.player.pause();
		return Promise.resolve();
	}


	mute() {
		this.player.muted = true;
		return Promise.resolve();
	}


	unMute() {
		this.player.muted = false;
		return Promise.resolve();
	}


	getEventData() {
		const duration = this.player.duration;
		const time = this.player.currentTime;
		const progress = (duration > 0 ? time / duration : 0);
		return {
			duration: duration,
			progress: progress,
			time: time
		};
	}


	getDuration() {
		return Promise.resolve(this.player.duration);
	}


	getSpeed() {
		return Promise.resolve(this.player.playbackRate);
	}


	getTime() {
		return Promise.resolve(this.player.currentTime);
	}


	/**
	 * @returns {Promise} Resolved with the intrinsic video height if available, 0 otherwise
	 */
	getVideoHeight() {
		return Promise.resolve(this.player.videoHeight);
	}


	/**
	 * @returns {Promise} Resolved with the intrinsic video width if available, 0 otherwise
	 */
	getVideoWidth() {
		return Promise.resolve(this.player.videoWidth);
	}


	/**
	 * @returns {Promise} Resolved with volume value in the range [0, 1]
	 */
	getVolume() {
		return Promise.resolve(this.player.volume);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isBuffering() {
		return Promise.resolve(this.status && this.status.buffering);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPaused() {
		return Promise.resolve(this.player.paused);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPlaying() {
		return Promise.resolve(this.status && this.status.playing);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isEnded() {
		return Promise.resolve(this.player.ended);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isMuted() {
		return Promise.resolve(this.player.muted);
	}

	/**
	 *
	 * @param {Number} speed: the new speed value
	 * @returns {Promise} resolve immediately, but it is not guaranteed that the speed change is actually happened
	 */
	setSpeed(speed) {
		this.player.playbackRate = speed;
		return Promise.resolve();
	}


	/**
	 * @param {Number|String} time: new position. Accepted formats: 190 (seconds) | 3:10 | 3m10s | 3m | 10s
	 * @returns {Promise} It resolve immediately without waiting the seek to be completed, returning the new time
	 */
	setTime(time) {
		this.player.currentTime = this.toSeconds(time);
		return Promise.resolve(this.player.currentTime);
	}


	/**
	 * @param {Number} volume: value in the range [0, 1]
	 * @returns {Promise} resolves immediately, returning the new volume value
	 */
	setVolume(volume) {
		this.player.volume = volume;
		return Promise.resolve(this.player.volume);
	}

}


export default SelfHostedVideoPlayerDriver;
