const isUrl = (value) => {
	let url;
	try {
		url = new URL(value);
	} catch (e) {
		return false;
	}
	return url.protocol === 'http:' || url.protocol === 'https:';
};

export default function (field) {
	if (field.type === 'tags') {
		if (Array.isArray(field.value)) {
			return this.escape(field.value.join(', '));
		}
	} else if(field.type === 'sum') {
		return this.render('form/field/sumTable', field, true);
	} else if(field.type === 'file') {
		if (field.value && 'size' in field.value && field.value.size) {
			let originalName = field.value.originalName;
			if (originalName.length > 40) {
				originalName = originalName.substr(0, 37) + '...';
			}
			let out = `<p>
				<a ${this.html.classAttr('link')} href="${this.url.getMediaUrl(field.value.path)}" target="_blank">${this.escape(originalName)}</a><br />
				${this.escape(field.value.ext.toUpperCase())} ${this.escape(this.string.fileSize(field.value.size))}
			</p>`;
			if (field.value.mime.indexOf('audio/') === 0 || ['.mp3'].indexOf(field.value.originalName.toLowerCase().substr(-4)) >= 0) {
				out += `
				<audio ${this.html.classAttr(['nativePlayer', 'nativePlayer--audio'])} controls preload="metadata" src="${this.url.getMediaUrl(field.value.path)}">
				</audio>`;
			} else if (field.value.mime.indexOf('video/') === 0 || ['.mp4', '.avi'].indexOf(field.value.originalName.toLowerCase().substr(-4)) >= 0) {
				out += `
				<video ${this.html.classAttr(['nativePlayer', 'nativePlayer--video'])} controls preload="metadata" src="${this.url.getMediaUrl(field.value.path)}">
				</video>`;
			}
			return out;
		}
		return '';
	} else if (field.type === 'text' && isUrl(field.value)) {
		return `<a ${this.html.classAttr('link')} href="${field.value}" target="_blank">${this.escape(field.value)}</a>`;
	}

	return this.string.nl2br(this.escape(field.value));
}
