import actionActionLink from './action/action-link';
import actionActionSubmit from './action/action-submit';

import dialogButtons from './dialog/buttons';
import dialogDialog from './dialog/dialog';

import formFieldArray from './form/field/array';
import formFieldCharsCounter from './form/field/chars-counter';
import formFieldCheckbox from './form/field/checkbox';
import formFieldDate from './form/field/date';
import formFieldDatetime from './form/field/datetime';
import formFieldError from './form/field/error';
import formFieldErrors from './form/field/errors';
import formFieldFile from './form/field/file';
import formFieldGroupImage from './form/field/group-image';
import formFieldGroup from './form/field/group';
import formFieldHidden from './form/field/hidden';
import formFieldImagefile from './form/field/imagefile';
import formFieldInputWrapper from './form/field/input-wrapper';
import formFieldLabel from './form/field/label';
import formFieldLangSelectorRadio from './form/field/lang-selector-radio';
import formFieldPassword from './form/field/password';
import formFieldPostField from './form/field/post-field';
import formFieldPreField from './form/field/pre-field';
import formFieldRadio from './form/field/radio';
import formFieldSelect from './form/field/select';
import formFieldTags from './form/field/tags';
import formFieldSum from './form/field/sum';
import formFieldSumTable from './form/field/sum-table';
import formFieldText from './form/field/text';
import formFieldTextarea from './form/field/textarea';
import formActions from './form/actions';
import formFieldActions from './form/field-actions';
import formField from './form/field';
import formFields from './form/fields';
import formFormClose from './form/form-close';
import formFormContent from './form/form-content';
import formFormOpen from './form/form-open';
import formForm from './form/form';
import formSortableHandle from './form/sortable-handle';

import pageAccountProfile from './page/account/profile';
import pageAccountRegister from './page/account/register';
import pageApplicationEdit from './page/application/edit';
import pageApplicationPreview from './page/application/preview';
import pageApplicationContract from './page/application/contract';
import pageApplicationContractCustom from './page/application/contract-custom';
import pageApplicationApplications from './page/application/applications';
import pageWrapper from './page/wrapper';

import applicationFieldValue from './application/field-value';
import applicationTab from './application/tab';

import searchNoResults from './search/no-results';
import searchPagination from './search/pagination';
import searchResultsItem from './search/results-item';
import searchResults from './search/results';
import searchTagsItems from './search/tags-items';
import searchTags from './search/tags';

import action from './action';
import activate from './activate';
import button from './button';
import changePassword from './change-password';
import forgotPassword from './forgot-password';
import login from './login';
import notification from './notification';
import tag from './tag';


const templates = {
	'action/actionLink': actionActionLink,
	'action/actionSubmit': actionActionSubmit,

	'dialog/buttons': dialogButtons,
	'dialog/dialog': dialogDialog,

	'form/field/array': formFieldArray,
	'form/field/charsCounter': formFieldCharsCounter,
	'form/field/checkbox': formFieldCheckbox,
	'form/field/date': formFieldDate,
	'form/field/datetime': formFieldDatetime,
	'form/field/error': formFieldError,
	'form/field/errors': formFieldErrors,
	'form/field/file': formFieldFile,
	'form/field/groupImage': formFieldGroupImage,
	'form/field/group': formFieldGroup,
	'form/field/hidden': formFieldHidden,
	'form/field/imagefile': formFieldImagefile,
	'form/field/inputWrapper': formFieldInputWrapper,
	'form/field/label': formFieldLabel,
	'form/field/langSelectorRadio': formFieldLangSelectorRadio,
	'form/field/password': formFieldPassword,
	'form/field/postField': formFieldPostField,
	'form/field/preField': formFieldPreField,
	'form/field/radio': formFieldRadio,
	'form/field/select': formFieldSelect,
	'form/field/tags': formFieldTags,
	'form/field/sum': formFieldSum,
	'form/field/sumTable': formFieldSumTable,
	'form/field/text': formFieldText,
	'form/field/textarea': formFieldTextarea,
	'form/actions': formActions,
	'form/fieldActions': formFieldActions,
	'form/field': formField,
	'form/fields': formFields,
	'form/formClose': formFormClose,
	'form/formContent': formFormContent,
	'form/formOpen': formFormOpen,
	'form/form': formForm,
	'form/sortableHandle': formSortableHandle,

	'page/account/profile': pageAccountProfile,
	'page/account/register': pageAccountRegister,
	'page/application/edit': pageApplicationEdit,
	'page/application/preview': pageApplicationPreview,
	'page/application/contract': pageApplicationContract,
	'page/application/contractCustom': pageApplicationContractCustom,
	'page/application/applications': pageApplicationApplications,
	'page/wrapper': pageWrapper,

	'application/fieldValue': applicationFieldValue,
	'application/tab': applicationTab,

	'search/noResults': searchNoResults,
	'search/pagination': searchPagination,
	'search/resultsItem': searchResultsItem,
	'search/results': searchResults,
	'search/tagsItems': searchTagsItems,
	'search/tags': searchTags,

	action: action,
	activate: activate,
	button: button,
	changePassword: changePassword,
	forgotPassword: forgotPassword,
	login: login,
	notification: notification,
	tag: tag,

};

// eslint-disable-next-line piggyback/no-restricted-global-extend
// window.appTemplates = templates;

export default templates;
