export default function (entry) {
	// entry.default = this.date.format('Y-m-d', entry.default);
	const [year, month, day] = (entry.default && entry.default.length && entry.default !== '1000-01-01' ? entry.default.split('-') : ['-', '-', '-']);
	const parts = [
		{key: 'day', value: day, options: entry.data.days},
		{key: 'month', value: month, options: entry.data.months},
		{key: 'year', value: year, options: entry.data.years}
	];
	return `
		${this.render('form/field/label', entry)}
		${this.render('form/field/inputWrapper', `
			${this.html.open({tag: 'div', classes: ['field__dateWrapper']})}
				${this.loop(parts, (part) => {
					const data = {};
					data[part.key] = null;
					return `
					${this.html.open({tag: 'div', classes: ['field__datePart', 'field__datePart--' + part.key]})}
						${this.html.open({
							tag: 'select',
							attributes: Object.assign({}, entry.attributes, {
								name: entry.inputName +  '_' + part.key,
								id: entry.id + '_' + part.key
							}),
							data: data,
							classes: ['field__input', 'field__input--select']
						})}
							${this.loop(part.options, (option) => {
								const label = option.label;
								const value = option.value;
								const attributes = {};
								if (part.value === String(value)) {
									attributes.selected = 'selected';
								}
								return this.html.element({
									tag: 'option',
									attributes: Object.assign({}, attributes, {value: value}),
									classes: ['field__selectOption'],
									content: this.escape(label)
								});
							})}
						${this.html.close()}
						<span ${this.html.classAttr('field__selectIcon')}>${this.html.svgIcon('down')}</span>
					${this.html.close()}
					`;
				})}
			${this.html.close()}
		`)}
	`;
}
