export default function (entry) {
	return `
		${this.render('form/field/label', entry)}
		${this.render('form/field/inputWrapper', `
			<div ${this.html.classAttr('field__fileInfo')}>
				<span ${this.html.classAttr('field__fileInfoText')} ${this.html.dataAttr({info: null})}></span>
			</div>
			<div ${this.html.classAttr('field__fileUploadProgress')} ${this.html.dataAttr({uploadProgress: null})}>
			</div>

			<div ${this.html.classAttr('field__fileButtonContainer')}>
				<span ${this.html.classAttr('field__imageUpload', 'button', 'button--upload')}>
					${this.html.element({tag: 'span', classes: ['button__label'], content: this.text.get('application/upload')})}
					${this.html.element({
						tag: 'input',
						attributes: Object.assign({}, entry.attributes, {
							type: 'file',
							name: entry.inputName,
							id: entry.id,
							'aria-label': this.text.get('application/upload')
						}),
						classes: ['field__input', 'field__input--file'],
						data: {fileInfo: entry.default}
					})}
				</span>
				<button ${this.html.classAttr('field__removeFile', 'button', 'button--removeFile')} ${this.html.dataAttr({removeFile: null})} type="button">
					<span ${this.html.classAttr('button__label')}>${this.text.get('application/delete')}</span>
					<span ${this.html.classAttr('button__icon')}>${this.html.svgIcon('close')}</span>
				</button>
			</div>
			<div ${this.html.classAttr('field__limitsInfo')}>${this.text.get('application/fileLimitsInfo', entry.data.limitsInfo)}</div>
		`)}
	`;
}
