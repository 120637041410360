import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import domMixin from '../dom/dom-mixin';
import offsetTop from '../utils/offset-top';

gsap.registerPlugin(ScrollToPlugin);


class HashScroller extends domMixin() {

    constructor({events, duration = 2}) {
		super();
		this.events = events;
		this.duration = duration;
		this.events.on(document, 'history:hashchange', this.onHashChange.bind(this));
    }


	onHashChange(event) {
		const hash = event.detail.hash;
		if (hash !== null && hash !== undefined) {
			let element;
			let position;
			if (hash.length === 0) {
				element = true;
				position = 0;
			} else {
				element = document.querySelector('#' + hash);
				if (!element) {
					element = document.querySelector(this.dataSelector('hash', hash));
				}
			}
			if (element) {
				requestAnimationFrame(() => {
					if (element !== true) {
						position = offsetTop(element);
					}
					gsap.to(window, {duration: this.duration, scrollTo: {y: position, autoKill: false}});
				});
			}
		}
	}

}


export default HashScroller;
