import {trim, leftTrim, rightTrim, ucFirst, lcFirst, camelize, decamelize, nl2br} from '../utils/string';
import {isString} from '../utils/types';
import TemplateHelper from './template-helper';


class StringHelper extends TemplateHelper {

	isString(value) {
		return isString(value);
	}


	trim(value, chars = undefined) {
		return trim(value, chars);
	}


	leftTrim(value, chars = undefined) {
		return leftTrim(value, chars);
	}


	rightTrim(value, chars = undefined) {
		return rightTrim(value, chars);
	}


	ucFirst(value) {
		return ucFirst(value);
	}


	lcFirst(value) {
		return lcFirst(value);
	}


	decamelize(value, separator = '-') {
		return decamelize(value, separator);
	}


	camelize(value, setUcFirst = false) {
		return camelize(value, setUcFirst);
	}


	nl2br(value) {
		return nl2br(value);
	}


	fileSize(value) {
		if (value) {
			value = parseInt(value, 10);
            const s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
            const e = Math.floor(Math.log(value) / Math.log(1024));
            return (Math.round((value / Math.pow(1024, Math.floor(e))) * 100) / 100) + s[e];
        }
        return '0 Bytes';
	}

}

export default StringHelper;
