const textFieldMixin = (Base = class Empty {}) => class extends Base {


	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('input');
		}
		return this.input;
	}


	writeValue(value) {
		this.getInput().value = value;
		return this;
	}


	readValue() {
		return this.getInput().value;
	}


	setFocus() {
		// trick to put the cursor at the end of the current value
		const input = this.getInput();
		const value = input.value;
		input.value = '';
		input.focus();
		input.value = value;
	}



	setBlur() {
		this.input.blur();
	}


	enableInput() {
		this.getInput().disabled = false;
	}


	disableInput() {
		this.getInput().disabled = true;
	}

};

export default textFieldMixin;
