import contextsMixin from '../context/contexts-mixin';


const dialogMixin = (Base = class Empty {}) => class extends contextsMixin(Base) {

	getDialog() {
		return this.contexts.getContext('dialog');
	}

};


export default dialogMixin;
