import DefaultContext from './default-context';
import OverlayContext from './overlay-context';
import AccountContext from './account-context';
import MenuContext from './menu-context';



export default (di) => {
	di
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})

		.setType({
			type: OverlayContext, name: 'OverlayContext', parent: 'Context', setters: {
				injectNavigation: di.lazyGet('navigation/navigation'),
				injectSlots: di.lazyGet('page/slots')
			},
			initCall: 'setEvents'
		})

		.setType({type: AccountContext, name: 'AccountContext', parent: 'Context', initCall: 'setEvents'})

		.setType({
			type: MenuContext, name: 'MenuContext', parent: 'Context'})
		;
};
