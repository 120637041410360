import project from 'consts:project';
import commonConfig from './common';

import accountConfig from '../account/_config';
import animationConfig from '../animation/_config';
import apiConfig from '../api/_config';
import appConfig from '../app/_config';
import contextConfig from '../context/_config';
import menuConfig from '../menu/_config';
import pageConfig from '../page/_config';
import printConfig from '../print/_config';
import applicationConfig from '../application/_config';

const config = async (di) => {
	commonConfig(di);

	accountConfig(di);
	animationConfig(di);
	apiConfig(di);
	appConfig(di);
	contextConfig(di);
	menuConfig(di);
	pageConfig(di);
	printConfig(di);
	applicationConfig(di);

	let langsPromise;
	let templatesPromise;
	switch (project) {
		case 'kfn':
			langsPromise = import('../locale/langs-kfn');
			templatesPromise = import('../templates/templates-kfn');
		break;

		case 'ldi':
			langsPromise = import('../locale/langs-ldi');
			templatesPromise = import('../templates/templates');
		break;

		default:
			langsPromise = import('../locale/langs');
			templatesPromise = import('../templates/templates');
	}

	const [langs, templates] = (await Promise.all([langsPromise, templatesPromise])).map((p) => p.default);

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	const apiUrl = bodyData.get('apiUrl');
	const registerUrl = bodyData.get('registerUrl');

	di
		.setValue('projectName', project)
		.setValue('baseUrl', baseUrl)
		.setValue('baseMediaUrl', bodyData.get('baseMediaUrl'))
		.setValue('baseThumbUrl', bodyData.get('baseThumbUrl'))
		.setValue('relPath', bodyData.get('relPath'))
		.setValue('applicationUrls', bodyData.get('applicationUrls'))
		.setValue('api/baseUrl', apiUrl)
		.setValue('account/registerUrl', registerUrl)
		.setValue('account/defaultUrl', bodyData.get('accountDefaultUrl'))
		.setValue('account/loggedDefaultUrl', bodyData.get('accountLoggedDefaultUrl'))

		.setValue('locale/langs', langs)
		.setValue('locale/defaultLanguage', 'de')

		.setValue('template/tpls', templates)

		.setParam('PageLoader', 'baseUrl', baseUrl)
		.setParam('Navigation', 'navigateSameUrl', true)

		.setParams('AccountContext', {popOnKey: false, closeOnClickOutside: false})

		//.setSetter('PageLoader', 'injectRouter', di.lazyGet('router/router'))

		.setParam('FrontendApp', 'requiredIconFiles', bodyData.get('iconFiles'))

		.addPostCreateCall('Template', (template) => {
			template.registerHelpers({
				text: di.newInstance('LocaleHelper'),
				html: di.newInstance('HtmlHelper'),
				date: di.newInstance('DateHelper'),
				id: di.newInstance('IdHelper'),
				string: di.newInstance('StringHelper'),
				url: di.newInstance('UrlHelper'),
				thumb: di.newInstance('ThumbHelper')
			});
		})

		.addPostCreateCall('PageComponentFactory', (factory) => {
			factory.setAliases({
				AccountManager: 'FrontendAccountManager'
			});
		})

	;
};


export default config;
