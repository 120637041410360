const langSelectorMixin = (Base = class Empty {}) => class extends Base {

	injectLocaleText(localeText) {
		this.locale = localeText;
	}


	injectApi(api) {
		this.api = api;
	}


	initLangSelector() {
		const defaultLanguage = this.locale.getDefaultLanguage();
		this.writeValue(defaultLanguage);
		this.remoteCallAction = this.dataAttr(this.element).get('remoteCallAction', false);
		this.listeners.internalChange = this.events.on(this.element, 'change', this.onInternalChange.bind(this));
		// this.listeners.externalChange = this.events.on(this.root, this.locale.getChangeEventName(), this.onLocaleTextLangChange.bind(this));
	}


	async onInternalChange(event) {
		const lang = this.readValue();
		this.locale.setDefaultLanguage(lang);
		if (this.remoteCallAction) {
			await this.api.execute(this.remoteCallAction, {element: {lang: lang}});
			location.reload();
		}
	}


	onLocaleTextLangChange(event) {
		if (event.detail.current !== this.readValue()) {
			this.writeValue(event.detail.current);
		}
	}


};


export default langSelectorMixin;
