import InteractiveField from './interactive-field';

/**
 * It can work with a single date field or with 3 dropdowns, based on the rendered markup
 */

const NO_VALUE = '-';
const EMPTY_DATE = '1000-01-01';
const SEP = '-';


class DateField extends InteractiveField {

	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('input');
			if (!this.input) {
				this.input = {
					day: this.element.querySelector(this.dataSelector('day')),
					month: this.element.querySelector(this.dataSelector('month')),
					year: this.element.querySelector(this.dataSelector('year'))
				};
			}
		}
		return this.input;
	}


	onChange(event) {
		if (this.eventsEnabled) {
			const input = this.getInput();
			if (input instanceof Element) {
				this.triggerEvent('change', event);
			} else {
				const year = this.input.year.value;
				const month = this.input.month.value;
				const day = this.input.day.value;
				const isEmpty = (day === NO_VALUE && month === NO_VALUE && year === NO_VALUE);
				const isFull = (!isEmpty && day !== NO_VALUE && month !== NO_VALUE && year !== NO_VALUE);
				const isValid = (isFull && this.isValidDate(year, month, day));
				if (isEmpty || isValid) {
					this.resetErrors();
					this.triggerEvent('change', event);
				} else if (isFull && !isValid) {
					this.showInvalidDateError();
				}
			}
		}
	}


	writeValue(value) {
		const input = this.getInput();
		if (input instanceof Element) {
			input.value = value;
		} else {
			let year = NO_VALUE;
			let month = NO_VALUE;
			let day = NO_VALUE;
			if (value !== EMPTY_DATE && value !== '') {
				[year, month, day] = value.split(SEP);
			}
			input.year.value = year;
			input.month.value = month;
			input.day.value = day;
		}
	}


	readValue() {
		const input = this.getInput();
		let value;
		if (input instanceof Element) {
			value = input.value;
		} else {
			const day = this.input.day.value;
			const month = this.input.month.value;
			const year = this.input.year.value;
			if (day === NO_VALUE || month === NO_VALUE || year === NO_VALUE) {
				value = '';
			} else {
				value = [year, month, day].join(SEP);
				if (value === EMPTY_DATE) {
					value = '';
				} else {
					if (!this.isValidDate(year, month, day)) {
						value = EMPTY_DATE;
						this.showInvalidDateError();
					}
				}
			}
		}
		return value;
	}


	showInvalidDateError() {
		this.setErrors([this.dataAttr().get('invalidDateError')]);
	}


	setFocus() {
		const input = this.getInput();
		if (input instanceof Element) {
			input.focus();
		} else {
			input.day.focus();
		}
	}


	setBlur() {
		const input = this.getInput();
		if (input instanceof Element) {
			input.blur();
		} else {
			input.day.blur();
			input.month.blur();
			input.year.blur();
		}
	}


	enableInput() {
		this.toggleEnabledInput(true);
	}


	disableInput() {
		this.toggleEnabledInput(false);
	}


	toggleEnabledInput(value) {
		const input = this.getInput();
		if (input instanceof Element) {
			input.disabled = !value;
		} else {
			input.day.disabled = !value;
			input.month.disabled = !value;
			input.year.disabled = !value;
		}
	}


	isValidDate(year, month, day) {
		// https://medium.com/@esganzerla/simple-date-validation-with-javascript-caea0f71883c
		const date = new Date(+year, +month - 1, +day);
		return (!!(+date) && date.getDate() === +day);
	}


}


export default DateField;
