import Page from '../../common/page/page';


class DefaultPage extends Page {

	onLoad() {
		if (!this.dataAttr(this.element).get('isAccountPage', false)) {
			this.classList(this.root).remove('account');
		}

		this.params = this.dataAttr(this.element).get('params', {});
		return super.onLoad();
	}


	onUnload() {
		return super.onUnload();
	}

}

export default DefaultPage;
