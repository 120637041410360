import AsyncPicture from './async-picture-legacy';
import SelfHostedVideoPlayerDriver from './self-hosted-video-player-driver';
import VideoPlayer from './video-player';
import VideoPlayerBg from './video-player-bg';
import VideoPlayerCover from './video-player-cover';
import VideoPlayerDriver from './video-player-driver';
import VideoPlayerDriverFactory from './video-player-driver-factory';
import VideoDiscovery from './video-discovery';


const config = (di) => {
	di
		.setType({type: AsyncPicture, name: 'AsyncPicture', parent: 'PageComponent'})
		.setType({type: SelfHostedVideoPlayerDriver, name: 'SelfHostedVideoPlayerDriver', parent: 'VideoPlayerDriver'})
		.setType({type: VideoPlayer, name: 'VideoPlayer', parent: 'PageComponent', setters: {
				injectVideoPlayerDriverFactory: di.lazyGet('media/videoPlayerDriverFactory')
			}
		})
		.setType({type: VideoPlayerBg, name: 'VideoPlayerBg', parent: 'PageComponent', setters: {
				injectIntersectionObservers: di.lazyGet('dom/intersectionObservers')
			}
		})
		.setType({type: VideoPlayerCover, name: 'VideoPlayerCover', parent: 'PageComponent'})
		.setType({type: VideoPlayerDriver, name: 'VideoPlayerDriver', mixins: ['domMixin']})
		.setType({type: VideoPlayerDriverFactory, name: 'VideoPlayerDriverFactory', params: {
				factory: di.lazyNew('Factory', {suffix: 'VideoPlayerDriver'})
			}
		})
		.set('media/videoPlayerDriverFactory', di.lazyNew('VideoPlayerDriverFactory'))

		.setType({type: VideoDiscovery, name: 'VideoDiscovery', parent: 'PageComponent', mixins: ['templateMixin']})
	;
};


export default config;
